import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Card, Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider } from "@material-ui/core";
import { Row, Col } from "antd";
import strings from "../../../Languages/Language";

const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 199,
    height: 3,
    backgroundColor: "#343379",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 7,
    marginBottom: 3,
  },
  VisionSpace: {
    marginTop: 51,
  },
  VisionSpace20: {
    marginTop: 20,
  },
  mainBox: {
    boxShadow: "0px 3px 6px #00000038",
    border: "1px solid #EFEFEF",
    background: "white",
    opacity: 1,
  },
  card: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000026",
    border: "1px solid #EFEFEF",
  },
}));
export default function FAQs() {
  const classes = useStyles();
  const [data, setdata] = useState(null);
  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/faqs`)
        .then((res) => {
          setdata(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <Box
      component={Card}
      p={2}
      style={{ background: "white" }}
      className="p-0 p-sm-2"
    >
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 30, fontWeight: 500 }}>
        {strings.AcademicrelatedFAQs}
      </Typography.Title>
      <div className={classes.VisionSpace20}></div>
      {data &&
        data.map((item, index) => {
          return <QuestionAndAnswer item={item} key={index} />;
        })}
    </Box>
  );
}

function QuestionAndAnswer({ item, key }) {
  const classes = useStyles();
  return (
    <>
      <Row>
        <Col xs={2}>
          <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
            {strings.Q}
          </Typography.Text>
        </Col>
        <Col xs={20}>
          <Typography.Paragraph style={{ fontWeight: 500, fontSize: 14 }}>
            <span className="f-14"> {item?.question}</span>
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
            {strings.A}
          </Typography.Text>
        </Col>
        <Col xs={20}>
          <Typography.Paragraph style={{ fontWeight: 400 }}>
            <span className="f-15"> {item?.answer}</span>
          </Typography.Paragraph>
        </Col>
      </Row>
      <div className={classes.VisionSpace20}></div>
      <Divider />
      <div className={classes.VisionSpace20}></div>
    </>
  );
}
