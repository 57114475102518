import React, { useEffect, useLayoutEffect, useState } from "react";
import { Typography } from "antd";
import { useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useLoading } from "../../Context/LoadingContext";
import Box from "@material-ui/core/Box";
import { APP_URL } from "../../config";
import axios from "axios";
import { Link } from "react-router-dom";

const View = () => {
  const [error, setError] = useState(null);
  const [institutionEvidenceContentData, setInstitutionEvidenceContentData] =
    useState([]);
  const [title, setTitle] = useState("");

  const { setIsPageLoading } = useLoading();

  const { id } = useParams();

  useLayoutEffect(() => {
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, []);
  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${APP_URL}/institution-evidence/${id}`
        );

        setInstitutionEvidenceContentData(
          response?.data?.institutionEvidenceContent
        );
        setTitle(response?.data?.institutionEvidenceTitle);
      } catch (err) {
        setError("Something went wrong");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    };

    fetchData();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box className="bg-main-different" marginBottom={0}>
        <div className="institution-evidence-page">
          {/* Go Back */}
          <div className="back-button">
            <i className="fa fa-long-arrow-right" />

            <Link to="/institution-evidence">Back</Link>
          </div>

          <div className="hero-section">
            <Typography.Text
              className="f-40 text-white"
              style={{ fontWeight: "500" }}
            >
              {title}
            </Typography.Text>
          </div>

          <div className="institution-evidence-content row">
            {institutionEvidenceContentData?.map((content) => {
              return (
                <div
                  key={content?.key ?? content?.id}
                  className="col-12 col-lg-4"
                >
                  <a href={content?.file} target="_blank">
                    <button className="btn">{content?.title}</button>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default View;
