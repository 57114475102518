import { Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../config";
import { useParams } from "react-router-dom";
const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 120,
    height: 3,
    backgroundColor: "#DDDCE9",
    marginTop: 10,
  },
  VisionSpace: {
    marginTop: 27,
  },
}));
function Degree({ pageOn }) {
  const { page } = useParams();
  const classes = useStyles();
  // eslint-disable-next-line
  const [degreeData, setdegreeData] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/degrees/${page}`)
        .then((res) => {
          setdegreeData(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, [page]);
  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <img src={degreeData?.image} className="auto" alt="" />
        <div className={classes.VisionSpace}/>
        <div
            className={"ql-editor"}
          style={{ fontSize: 15 }}
          dangerouslySetInnerHTML={{
            __html: degreeData?.description,
          }}
        />
        <br />
        <br />
        <Typography.Text style={{ fontSize: 15 }}>
          {/* {strings.Degree2} */}
        </Typography.Text>
      </div>
    </>
  );
}

export default Degree;
