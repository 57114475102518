import React, { useEffect, useState } from "react";
import { Card, Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import strings from "../../../Languages/Language";
import axios from "axios";
import { APP_URL } from "./../../../config";
const useStyles = makeStyles(() => ({
  spacing72: {
    marginTop: 72,
    "@media (max-width: 576px)": {
      marginTop: 40,
    },
  },
  spacing105: {
    marginTop: 105,
    "@media (max-width: 576px)": {
      marginTop: 40,
    },
  },
  feeStructureCard: {
    padding: "35px 10px",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #D4D2D2",
  },
}));
function FeeStructure() {
  const [FeeStructureData, setFeeStructureData] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = () => {
      axios
        .get(`${APP_URL}/fee`)
        .then((res) => {
          setFeeStructureData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  const classes = useStyles();
  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <Typography.Title style={{ fontSize: 20 }}>
          {strings.KIUFees}
        </Typography.Title>
        <div className={"ql-editor"} dangerouslySetInnerHTML={{__html: FeeStructureData?.about}}/>
        <div className={classes.spacing72}/>

        <div className="row courses-fees-cards justify-content-center text-center">
          {/* /first */}
          <div className="col-lg-4 col-sm-12 col-md-12 py-2 py-sm-0 my-2 courses-fees-card">
            <div className="py-3 px-1 course-fees-content">
              <div
                style={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography.Paragraph className="text-center ">
                  <span className="f-18 fw-600 d-block pb-2">
                    {strings.ApplicationFees}
                  </span>
                </Typography.Paragraph>
                <Typography.Paragraph className="f-18 text-center text-main fw-500 mb-0">
                  <span className="f-18 fw-600">
                    {FeeStructureData.application_fee} {FeeStructureData.currency}
                  </span>
                  <br />
                </Typography.Paragraph>
              </div>
            </div>
          </div>
          {/* second */}
          <div className="col-lg-4 col-sm-12 col-md-12 py-2 py-sm-0 my-2 courses-fees-card">
            <div className="py-3 px-1 course-fees-content">
              <div
                style={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography.Paragraph className="f-18 text-center fw-500 mb-0">
                  <span className="f-18 fw-600 d-block pb-2">
                    {strings.FullTimeStudents}
                    <small className="text-submain f-11 d-block">
                      (15-21 credit hours per semester)
                    </small>
                  </span>
                  <span className="f-18 fw-600 text-main">
                    {FeeStructureData.full_time} {FeeStructureData.currency}
                  </span>
                </Typography.Paragraph>

                <Typography.Paragraph className="f-18 text-center text-main fw-500 mb-0">
                  <span className="f-18 fw-600">
                    {FeeStructureData.applicationFee}
                  </span>
                  <br />
                  <small className="text-main f-11">
                    {strings.PerSemester}{" "}
                  </small>
                </Typography.Paragraph>
              </div>
            </div>
          </div>
          {/* third */}
          <div className="col-lg-4 col-sm-12 col-md-12 py-2 py-sm-0 my-2 courses-fees-card">
            <div className="py-3 px-1 course-fees-content">
              <div
                style={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography.Paragraph className="f-18 text-center fw-500 mb-0">
                  <span className="f-18 fw-600 d-block pb-2">
                    {strings.OneCourseModule}
                  </span>
                </Typography.Paragraph>
                <Typography.Paragraph className="text-center text-main  mb-0">
                  <span className="f-18 fw-600">
                    {FeeStructureData.one_time} {FeeStructureData.currency}
                  </span>
                  <br />
                  <small className="text-main f-11">
                    {strings.RunsDuringTermTime}
                  </small>
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.spacing72}/>
        <Typography.Title style={{ fontSize: 20 }}>
          {strings.ScholarshipEntitlement}
        </Typography.Title>
        <p  dangerouslySetInnerHTML={{
          __html:  FeeStructureData.scholarship_entitlement,
        }}/>

        <div className={classes.spacing105}/>

        <Typography.Title style={{ fontSize: 20 }}>
          {strings.PaymentInstuctions}
        </Typography.Title>
        <p dangerouslySetInnerHTML={{
          __html:  FeeStructureData.payment_instructions,
        }}/>

        <Card
          bordered
          style={{
            maxWidth: 539,
            border: "1px solid #DBDBDB",
            backgroundColor: "#F9F9F9",
          }}
        >
          <Typography.Paragraph>
            <span className="f-14 fw-500">{strings.Bank}:</span>
            <span> {FeeStructureData.bank}</span>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <span className="f-14 fw-500">{strings.Branch}:</span>
            <span>{FeeStructureData.branch}</span>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <span className="f-14 fw-500">{strings.BeneficiaryName}:</span>
            <span>{FeeStructureData.beneficiary_name}</span>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <span className="f-14 fw-500">{strings.Account}:</span>
            <span> {FeeStructureData.account_number}</span>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <span className="f-14 fw-500">{strings.SwiftCode}:</span>
            <span>{FeeStructureData.swift_code}</span>
          </Typography.Paragraph>
        </Card>
        <br />
        <p className="text-left"  dangerouslySetInnerHTML={{
          __html:  FeeStructureData.bottom_text,
        }}/>
          {/* Please make sure to send a copy of your payment via e-mail to
          <a href="mailto:bais@kiu.orgbais@kiu.org" className="fw-500 f-14">
            bais@kiu.orgbais@kiu.org
          </a>
          <br />
          or you may fax a copy of the payment to fax:
          <a href="tel:+966 11 4807880" className="fw-500 f-14">
            +966 11 4807880
          </a> */}

      </div>
    </>
  );
}

export default FeeStructure;
