import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { APP_URL } from "./../../../config";
import VerticalCard from "./VerticalNewsCard";
import strings from "../../../Languages/Language";
import axios from "axios";

const NewsCard = () => {
  // states
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);

  // use effect
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/news`)
        .then((res) => {
          setNews(res.data);
        })
        .catch((err) => {
          setError("something went wrong");
          setTimeout(() => {
            setError(null);
          }, 2000);
        });
    };
    init();
  }, []);

  const maxLength = 150;
  if (news.length === 0) return <div />;

  return (
    <div className="w-100 home-bg-light py-md-5 py-2 newCard">
      {error && <div>{error}</div>}
      <div className="d-lg-flex d-md-flex flex-wrap w-100 w-responsive-10 justify-content-around">
        <div className="width-100">
          <ul className="list-inline news-heading">
            <li className="list-inline-item">
              <h2 className="m-0 f-40 fw-400 mr-2">{strings.News}</h2>
            </li>
            <li className="list-inline-item f-14 fw-400">
              <Link to="/about-us/6">
                <span className="py-1">{strings.ViewAll}</span>
              </Link>
            </li>
          </ul>
        </div>

        {news?.length > 0 && (
          <Link
            to={`/about-us/news/${news[0].id}`}
            className="text-decoration-none w-40-per"
          >
            <div className="w-sm-v-item px-2 news-card w-100">
              <div
                className="card"
                style={{
                  backgroundImage: `url(${[news[0].image]})`,
                  Height: "370px",
                }}
              >
                <div className="card-body card-news p-3 simple-card-border">
                  <p className="card-title f-14 fw-500">{[news[0].title]}</p>
                  <p className="card-text f-14 fw-400 pb-4">{`${news[0].description.substring(
                    0,
                    maxLength
                  )}...`}</p>
                  <div
                    className="d-flex align-items-center mb-3"
                    style={{ position: "absolute", bottom: 0 }}
                  >
                    <i className="material-icons f-20">date_range</i>
                    <span className="mx-4 f-12 fw-400">{[news[0]?.date]}</span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
        {/* vertical card */}
        {news?.length > 0 && <VerticalCard news={news} />}
      </div>
    </div>
  );
};

export default NewsCard;
