import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Typography } from "antd";
import strings from "../../../Languages/Language";

import moment from "moment";
function AdmissionScholarship() {
  const [SemesterData, setSemesterData] = useState(null);
  const [error, seterror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${APP_URL}/admissions/deadline`)
      .then((res) => {
        setSemesterData(res.data);
      })
      .catch((err) => {
        seterror("something went wrong");
        setTimeout(() => {
          seterror("");
        }, 2000);
      });
  }, []);
  return (
    <div>
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.AdmissionDeadline}
      </Typography.Title>
      <Typography.Paragraph className="fw-500 f-15 mb-5 mt-3">
         {strings.AdmissionDeadlineDecription}
      </Typography.Paragraph>

      {/* Semester details */}
      {SemesterData &&
        SemesterData.map((item,index) => {
          return (
            <div key={index} className="semester-details">
              <div className="text-center text-sm-left mb-2">
                <span className="fw-600 f-16">Spring</span>
              </div>
              <div className="d-flex justify-content-center admission-details mb-5">
                <div className="w-nowrap">
                  <Typography.Paragraph className="fw-600 f-15 my-1">
                    {strings.StartDate}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="f-15">
                    {moment(item.spring_starting_date).format("YYYY-MM-DD")}
                  </Typography.Paragraph>
                </div>
                <hr />
                <div className="w-nowrap">
                  <Typography.Paragraph className="fw-600 f-15 m-1">
                    {strings.LastDate}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="f-15">
                    {moment(item.spring_last_date).format("YYYY-MM-DD")}
                  </Typography.Paragraph>
                </div>
                {/* horizontal border */}
                <hr />
                <div className="w-nowrap">
                  <Typography.Paragraph className="fw-600 f-15 m-1">
                    {strings.FinalExam}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="f-15">
                    {moment(item.spring_final_exams).format("YYYY-MM-DD")}
                  </Typography.Paragraph>
                </div>
              </div>

              <div className="text-center text-sm-left mb-2">
                <span className="fw-600 f-16">Fall</span>
              </div>
              <div className="d-flex justify-content-center admission-details mb-5">
                <div className="w-nowrap">
                  <Typography.Paragraph className="fw-600 f-15 my-1">
                    {strings.StartDate}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="f-15">
                    {moment(item.fall_starting_date).format("YYYY-MM-DD")}
                  </Typography.Paragraph>
                </div>
                <hr />
                <div className="w-nowrap">
                  <Typography.Paragraph className="fw-600 f-15 m-1">
                    {strings.LastDate}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="f-15">
                    {moment(item.fall_last_date).format("YYYY-MM-DD")}
                  </Typography.Paragraph>
                </div>
                {/* horizontal border */}
                <hr />
                <div className="w-nowrap">
                  <Typography.Paragraph className="fw-600 f-15 m-1">
                    {strings.FinalExam}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="f-15">
                    {moment(item.fall_final_exams).format("YYYY-MM-DD")}
                  </Typography.Paragraph>
                </div>
              </div>

              {/* fall */}
            </div>
          );
        })}
    </div>
  );
}

export default AdmissionScholarship;
