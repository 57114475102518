import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Typography } from "antd";
import strings from "../../../Languages/Language";
export default function Requirements() {
  const [requirements, setRequirements] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${APP_URL}/requirements`)
      .then((res) => {
        setRequirements(res.data);
      })
      .catch((err) => {
        seterror("something went wrong");
        setTimeout(() => {
          seterror("");
        }, 2000);
      });
  }, []);
  return (
    <div>
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.Requirements}
      </Typography.Title>
      <div
           className={"ql-editor"}
        dangerouslySetInnerHTML={{ __html: requirements.requirements }}
      ></div>
    </div>
  );
}
