import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import strings from "../../../Languages/Language";
import axios from "axios";
import { APP_URL } from "./../../../config";
export default function VideoLectures() {
  const [data, setdata] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/video-lecture`)
        .then((res) => {
          setdata(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <div>
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.VideoLectures}
      </Typography.Title>
      <div
          className={"ql-editor"}
        dangerouslySetInnerHTML={{
          __html: data.description,
        }}
      ></div>
    </div>
  );
}
