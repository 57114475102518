import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Card, Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import strings from "../../../Languages/Language";

const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 199,
    height: 3,
    backgroundColor: "#343379",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 7,
    marginBottom: 3,
  },
  VisionSpace: {
    marginTop: 51,
  },
  VisionSpace20: {
    marginTop: 20,
  },
  mainBox: {
    boxShadow: "0px 3px 6px #00000038",
    border: "1px solid #EFEFEF",
    background: "white",
    opacity: 1,
  },
  card: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000026",
    border: "1px solid #EFEFEF",
  },
}));
export default function ContactUs() {
  const classes = useStyles();
  const [data, setdata] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/contactus`)
        .then((res) => {
          setdata(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <Box
      component={Card}
      p={2}
      style={{ background: "white" }}
      className="about-us-contact"
    >
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 30 }}>
        {strings.ContactUs}
      </Typography.Title>
      <div className={classes.VisionSpace}></div>
      <Typography.Title
        style={{ fontSize: 18, fontWeight: 500 }}
        className="text-main"
      >
        {strings.AdministratorAddress}
      </Typography.Title>
      <Box p={2}>
        <Typography.Paragraph>
          <span
            style={{
              fontWeight: 500,
              whiteSpace: "nowrap",
              width: 80,
              marginLeft: 0,
            }}
            className=" d-inline-block"
          >
            {strings.POBOX}
          </span> <br/>
          <span style={{ marginLeft: 10,marginRight:30 }}>{data.po_box}</span>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <span
            className=" d-inline-block"
            style={{ fontWeight: 500, whiteSpace: "nowrap", width: 80 }}
          >
            {strings.City}
          </span> <br/>
          <span style={{ marginLeft: 10,marginRight:30 }}>{data.city}</span>
        </Typography.Paragraph>
      </Box>

      <div className={classes.VisionSpace}/>
      <Typography.Title
        style={{ fontSize: 18, fontWeight: 500 }}
        className="text-main"
      >
        {strings.KingdomofSaudiArabia}
      </Typography.Title>
      <Box p={2}>
        <Typography.Paragraph>
          <span
            className=" d-inline-block"
            style={{ fontWeight: 500, whiteSpace: "nowrap", width: 80 }}
          >
            {strings.Email}
          </span> <br/>
          <span style={{ marginLeft: 10,marginRight:30 }}>{data.email}</span>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <span
            className=" d-inline-block"
            style={{ fontWeight: 500, whiteSpace: "nowrap", width: 80 }}
          >
            {strings.Fax}
          </span> <br/>
          <span style={{
            marginLeft: 10,
            marginRight:30,
            direction: 'ltr',
            unicodeBidi: 'bidi-override',
          }}>{data.fax}</span>
        </Typography.Paragraph>
      </Box>
    </Box>
  );
}
