const VerticalStepper = ({ data, setPageOn, pageOn }) => {
  let value = 1;
  return (
    <div className="vertical-stepper-container my-5">
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="row justify-content-center vertical-stepper"
            onClick={() => {
              setPageOn(item[2]);
              window.scrollTo(0, 0);
            }}
          >
            <div
              className={
                index + 1 === data.length
                  ? "col-3 col-sm-3 col-md-5 col-lg-4 col-xl-3"
                  : "col-3 col-sm-3 dots col-md-5 col-lg-4 col-xl-3"
              }
            >
              <div className="dotted ">
                <div
                  className={
                    pageOn === item[2] ? "circle circle-active" : "circle"
                  }
                >
                  <div className="circle-value">{value++}</div>
                </div>
              </div>
            </div>
            <div
              className={
                pageOn === item[2]
                  ? "col-9 content-active content my-2 col-sm-6 col-md-7 col-lg-8 col-xl-9"
                  : "col-9 content my-2 col-sm-6 col-md-7 col-lg-8 col-xl-9"
              }
            >
              <h6>{item[0]}</h6>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VerticalStepper;

// Read Me

// pass object containing
// 1: data 2: stateHandler Method 3: Active page state variable as prop.
