import React, { createContext, useContext } from "react";

const LoadingContext = createContext();
export const useLoading = () => useContext(LoadingContext);

export default function LoadingProvider(props) {
  const [isPageLoadingState, setIsPageLoadingState] = React.useState(false);

  const setIsPageLoading = (loading) => {
    return setIsPageLoadingState(loading);
  };

  return (
    <LoadingContext.Provider value={{ isPageLoadingState, setIsPageLoading }}>
      {props?.children}
    </LoadingContext.Provider>
  );
}
