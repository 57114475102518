import React, { useState, useEffect } from "react";
import { APP_URL } from "../../../config";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import strings from "../../../Languages/Language";
import axios from "axios";

function InstitutionAndEvidence() {
  const [error, setError] = useState(null);
  const [institutionEvidenceData, setInstitutionEvidenceData] = useState([]);

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APP_URL}/institution-evidence`);
        setInstitutionEvidenceData(response?.data?.institutionEvidence);
      } catch (err) {
        setError("Something went wrong");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      style={{
        minHeight: 800,
      }}
      className="institution-evidence-tab"
    >
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.InstitutionAndEvidence}
      </Typography.Title>

      <div className="my-4 row">
        {institutionEvidenceData?.map((content) => {
          return (
            <div
              key={content?.key ?? content?.id}
              className="col-12 col-lg-4 my-3 mb-4 d-flex justify-content-center f-14 fw-500 text-center"
            >
              <Link to={`/about-us/institution-evidence/${content?.id}`}>
                <button className="btn">{content.title}</button>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InstitutionAndEvidence;
