import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sidebar from "./layout/Sidebar";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import LanguageProvider from "./Context/LanguageContext";
import LoadingProvider from "./Context/LoadingContext";
import 'rc-texty/assets/index.css';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <LoadingProvider>
      <LanguageProvider>
        <Sidebar />
      </LanguageProvider>
    </LoadingProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
