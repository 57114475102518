import React, {useEffect, useState} from "react";
import { Tabs } from "antd";
import HomeTabContent from "../common/HomeTabContent";
import AccordionStyleTabs from "../common/AccordionStyleTabs";
import Media from "react-media";
import strings from "../../../Languages/Language";
import axios from "axios";
import {APP_URL} from "../../../config";
import TeachingTabContent from "../common/TeachingTabContent";
import {lmsPortalLoginUrl, scholarshipUrl, sisPortalLoginUrl} from "../../../Constants/globals";

const { TabPane } = Tabs;

function ApplicationProcess() {
  const [interactiveLectureData, setInteractiveLectureData] = useState([]);
  const [studentGuideData, setStudentGuideData] = useState([]);
  const [academicCalendarData, setAcademicCalendarData] = useState([]);
  const [teachingsData, setTeachingsData] = useState([]);
  const [appendixData, setAppendixData] = useState([]);
  const [error, setError] = useState(null);

  // getting active language
  const locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'ar';

  useEffect(() => {
    let endpoints = [
      `${APP_URL}/interactive/lecture`,
      `${APP_URL}/student/guide`,
      `${APP_URL}/calender`,
      `${APP_URL}/teachings`,
      `${APP_URL}/appendix`,
    ];

    const init = () => {
      axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
          axios.spread((function(lectures, guides, calendar, teachings, appendix) {
            setInteractiveLectureData(lectures.data);
            setStudentGuideData(guides.data);
            setAcademicCalendarData(calendar.data);
            setTeachingsData(teachings.data);
            setAppendixData(appendix.data);
          })
        )
      )
      .catch((err) => {
        setError("Something went wrong");
        setTimeout(() => {
          setError("");
        }, 2000);
      });
    }

    init();
  }, []);

  const tabContent = {
    applicationContent: {
      name: strings.AdmissionAndRegistration,
      description: strings.AdmissionAndRegistrationDescription,
      buttons: [
        { key: "1", label: strings.ApplyNow, url: "https://admission.kiu.org/student/login" },
        { key: "2", label: strings.Programs, url: "/courses" },
        { key: "3", label: strings.Scholarships, url: scholarshipUrl[locale] },
      ],
    },
    studentGuide: {
      name: strings.StudentGuide,
      description: strings.StudentGuideDescription,
      buttons: studentGuideData,
    },
    interactiveLectures: {
      name: strings.InteractiveLectures,
      description: strings.InteractiveLecturesDescription,
      buttons: interactiveLectureData,
    },
    portalLogin: {
      name: strings.PortalLogin,
      description: strings.PortalLoginDescription,
      buttons: [
        { key: "1", label: strings.SisPortal, url: sisPortalLoginUrl[locale] },
        { key: "2", label: strings.LMSLogin, url: lmsPortalLoginUrl[locale] },
        { key: "3", label: strings.AdmissionLogin, url: "https://admission.kiu.org/student/login" },
      ],
    },
    academicCalender: {
      name: strings.AcademicCalendar,
      description: strings.AcademicCalendarDescription,
      buttons: academicCalendarData,
    },
    teachingMethodology: {
      name: strings.TeachingMethodology,
      description: '',
      buttons: teachingsData,
    },
    appendixRegulations: {
      name: strings.AppendixAndRegulations,
      description: strings.AppendixAndRegulationsDescription,
      buttons: appendixData,
    },
  };
  return (
    <div>
      <div>
        <Media
          query="(min-width: 800px)"
          render={() => (
            <div className="ApplicationProcess  ">
              <div className="card-container" />
              <Tabs type="card" centered defaultActiveKey="1">
                {/* ////////////////////// Admission & Application Tab ////////////////////// */}

                <TabPane
                  tab={strings.AdmissionAndRegistration}
                  key="1"
                  className="tab-pane-content "
                >
                  <div className="container-tabs bg-main">
                    <HomeTabContent
                      tabContent={tabContent.applicationContent}
                    />
                  </div>
                </TabPane>
                {/* ////////////////////// Interactive Lectures Tab ////////////////////// */}
                <TabPane tab={strings.StudentGuide} key="2">
                  <div className="container-tabs bg-main">
                    <HomeTabContent tabContent={tabContent.studentGuide} />
                  </div>
                </TabPane>
                {/* ////////////////////// Interactive Lectures Tab ////////////////////// */}
                <TabPane tab={strings.InteractiveLectures} key="3">
                  <div className="container-tabs bg-main">
                    <HomeTabContent
                      tabContent={tabContent.interactiveLectures}
                    />
                  </div>
                </TabPane>
                {/* ////////////////////// Portal Login ////////////////////// */}
                <TabPane
                  tab={strings.PortalLogin}
                  key="4"
                  className="align-text"
                >
                  <div className="container-tabs bg-main">
                    <HomeTabContent tabContent={tabContent.portalLogin} />
                  </div>
                </TabPane>
                {/* ////////////////////// Academic Calender ////////////////////// */}
                <TabPane tab={strings.AcademicCalendar} key="5">
                  <div className="container-tabs bg-main">
                    <HomeTabContent tabContent={tabContent.academicCalender} />
                  </div>
                </TabPane>
                {/* ////////////////////// Teaching Methodology ////////////////////// */}
                <TabPane tab={strings.TeachingMethodology} key="6">
                  <div className="container-tabs bg-main">
                    <TeachingTabContent tabContent={tabContent.teachingMethodology} />
                  </div>
                </TabPane>
                {/* ////////////////////// Appendix & Regulations ////////////////////// */}
                <TabPane tab={strings.AppendixAndRegulations} key="7">
                  <div className="container-tabs bg-main">
                    <HomeTabContent tabContent={tabContent.appendixRegulations} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}
        />
      </div>

      {/* Responsive Accordion tabs */}
      <AccordionStyleTabs />
      {/* end */}
    </div>
  );
}

export default ApplicationProcess;
