import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLanguage } from "../../../Context/LanguageContext";
import { APP_URL } from "../../../config";
import Slider from "react-slick";
import axios from "axios";
import clsx from "clsx";
import AvatarProfile from "../../../assets/images/avatar.svg";
import BigCommaIcon from "../../../assets/icons/comma.svg";

const useStyles = makeStyles(() => ({
  media: {
    width: "100%",
    height: "620px",
    position: "relative",
    zIndex: "-1",
  },
  Carousel: {
    "& .slick-dots": {
      bottom: 40,
      "& li button::before": {
        fontSize: 12,
        color: "white",
        opacity: 1,
      },
      "& .slick-active button::before": {
        color: "#079751",
      },
    },
    "& .slick-next": {
      // right: 150,
      // left: 120,
      width: 62,
      height: 62,
      top: "50%",
      transform: "translateY(-50%)",
      "&::before": {
        fontSize: "0px !important",
        opacity: 0,
      },
    },
    "& .slick-prev": {
      // language position issue
      zIndex: 1,
      width: 62,
      height: 62,
      top: "50%",
      transform: "translateY(-50%)",
      "&::before": {
        opacity: 0,
        fontSize: "0px !important",
      },

      ".slick-prev:hover:before": {
        opacity: 1,
      },
    },
  },
}));

function Testimonial() {
  const { isRTL } = useLanguage();
  const [carouselData, setCarouselData] = useState([]);
  const [error, setError] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: isRTL,
    arrows: false,
    nextArrow: false,
    prevArrow: false,
  };

  const classes = useStyles();

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/testimonials`)
        .then((res) => {
          setCarouselData(res.data);
        })
        .catch(() => {
          setError("something went wrong");
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    };
    init();
  }, []);

  return (
    <>
      {error && <div>{error}</div>}
      {/*new*/}

      <div className={"home-bg-light py-md-6 newCard testimonial-slider"}>
        <Slider
          {...settings}
          className={clsx(
            classes.Carousel,
            isRTL ? "carousel-rtl" : "carousel-ltr"
          )}
        >
          {carouselData?.map((data) => {
            return (
              <div
                className={
                  "d-flex flex-column justify-content-center align-items-center"
                }
              >
                <img src={BigCommaIcon} alt="big-comma" className={"m-b-40"} />
                <div
                  className={
                    "ql-editor w-lg-50 w-75 text-center m-b-25 f-18 fw-400"
                  }
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                />

                <div className="media">
                  <img
                    src={data?.image ?? AvatarProfile}
                    className="align-self-start mr-3 testimonial-profile"
                    alt="profile"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 f-14 fw-400">
                      {data?.person_name}, {data?.profession_title}
                    </h5>
                    <p className={"f-14 fw-300 mb-0"}>{data?.created_at}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      {/*old */}
      {/*{carouselData?.carosal_data.length && (*/}
      {/*  <div dir="ltr">*/}
      {/*    <Slider*/}
      {/*      {...settings}*/}
      {/*      className={clsx(*/}
      {/*        classes.Carousel,*/}
      {/*        isRTL ? "carousel-rtl" : "carousel-ltr"*/}
      {/*      )}*/}
      {/*    >*/}
      {/*      {carouselData?.carosal_data?.map((carItem, index) => (*/}
      {/*        <div*/}
      {/*          key={index}*/}
      {/*          style={{ position: "relative" }}*/}
      {/*          className="d-flex justify-content-center carousel-content"*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            style={{*/}
      {/*              backgroundSize: "cover",*/}
      {/*              backgroundPosition: "center",*/}
      {/*              backgroundImage: `url(${carItem.image})`,*/}
      {/*            }}*/}
      {/*            className={classes.media}*/}
      {/*          />*/}
      {/*          <div className="slick-carousel-info">*/}
      {/*            {!isPageLoadingState && <div*/}
      {/*                dangerouslySetInnerHTML={{ __html: carItem?.image_text}}*/}
      {/*                style={{*/}
      {/*                  fontSize: 50,*/}
      {/*                  fontWeight: 700,*/}
      {/*                  textAlign: "center",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      ))}*/}
      {/*    </Slider>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
}

function CarouselNextArrow(props) {
  const { isRTL } = useLanguage();

  const { className, onClick } = props;
  return (
    <div
      style={{
        display: "block",
      }}
      className={className}
      onClick={onClick}
    >
      <div className="next-arrow-carousel">
        <div className="carousel-arrow">
          {isRTL ? (
            <i className="material-icons change-direction">
              keyboard_arrow_right
            </i>
          ) : (
            <i className="material-icons">keyboard_arrow_right</i>
          )}
        </div>
      </div>
    </div>
  );
}

function CarouselPrevArrow(props) {
  const { isRTL } = useLanguage();

  const { className, onClick } = props;
  return (
    <div
      style={{
        display: "block",
      }}
      className={className}
      onClick={onClick}
    >
      <div className="next-arrow-carousel">
        <div className="carousel-arrow">
          asd
          {isRTL ? (
            <i className="material-icons ">keyboard_arrow_right</i>
          ) : (
            <i className="material-icons change-direction">
              keyboard_arrow_right
            </i>
          )}
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
