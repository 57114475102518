import React from "react";
function ProgressBar({ value }) {
  return (
    <div className="w-50 mx-auto">
      {
        /* <Progress
        percent={value}
        size='small'
        showInfo={false}
        strokeColor='#079751'
        style={{borderRadius: 0, height: 20}}
      /> */
        <div
          className="progress"
          style={{
            borderRadius: 0,
            height: "7px",
            border: "1px solid #C1C1C1",
          }}
        >
          <div
            className="progress-bar"
            style={{
              width: `${value}%`,
              backgroundColor: "#079751",
            }}
          > </div>
        </div>
      }
    </div>
  );
}

export default ProgressBar;
