import React from "react";

const TeachingTabContent = ({ tabContent }) => {
    return (
        <div className="w-100 d-flex justify-content-center home-tab-content teaching-tab-content">
            <div className="w-50">
                <div className="my-5">
                    <div className="my-3 row">
                        {tabContent.buttons.map((button) => {
                            return (
                                <div
                                    key={button?.id ?? button?.key}
                                    className="d-flex flex-column justify-content-start mb-3"
                                >
                                    <h6 className={"f-16 fw-500 text-heading"}>{button?.heading}</h6>
                                    <div
                                        className={"ql-editor p-0 f-15 fw-400"}
                                        dangerouslySetInnerHTML={{
                                            __html: button?.description,
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Read Me

// pass object containing tab Content:
// Object should contains description and buttons /w its unique key and buttons.
// data i-e : applicationContent: {description: "your desc", buttons: [{key: "1", label: "any label"}, ],},

export default TeachingTabContent;
