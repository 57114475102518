import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Typography, Card } from "antd";
import { Box } from "@material-ui/core";
import strings from "../../../Languages/Language";

export default function EnrollmentProcess() {
  const [enrolment, setEnrolment] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${APP_URL}/enrollment`)
      .then((res) => {
        setEnrolment(res.data);
      })
      .catch((err) => {
        seterror("something went wrong");
        setTimeout(() => {
          seterror("");
        }, 2000);
      });
  }, []);
  return (
    <div>
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.EnrollmentProcess}
      </Typography.Title>

      <div
        className="fw-600 ql-editor"
        dangerouslySetInnerHTML={{ __html: enrolment.requirements }}
      ></div>
      <div className="space37"></div>
      <Typography.Paragraph className="fw-300">
        {/* {strings.Lorem} */}
      </Typography.Paragraph>
      <Card
        bordered
        className="fw-500 f-14"
        style={{ maxWidth: 539, border: "1px solid #DBDBDB" }}
      >
        <Box>
          <Typography.Paragraph>{strings.ByEmail}</Typography.Paragraph>
          <Typography.Paragraph>
            {enrolment.email} <br />
            <small className="fw-300 f-14"></small>
          </Typography.Paragraph>
        </Box>

        <Box>
          <Typography.Paragraph>{strings.ByFax}</Typography.Paragraph>
          <Typography.Paragraph>{enrolment.fax}</Typography.Paragraph>
        </Box>
        <Box>
          <Typography.Paragraph>{strings.ByPost}</Typography.Paragraph>
          <Typography.Paragraph>{enrolment.post_address}</Typography.Paragraph>
        </Box>
        <Box>
          <Typography.Paragraph>{strings.POBOX}</Typography.Paragraph>
          <Typography.Paragraph>{enrolment.po_box}</Typography.Paragraph>
        </Box>
      </Card>
    </div>
  );
}
