import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Typography, Row, Col, Card } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { EventComponent } from "../Home/Events";
import { Link } from "react-router-dom";
import strings from "../../../Languages/Language";
function NewsAndEvents() {
  const [news, setnews] = useState(null);
  const [events, setevents] = useState(null);
  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/news`)
        .then((res) => {
          setnews(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
      axios
        .get(`${APP_URL}/events`)
        .then((res) => {
          setevents(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <div
      style={{
        minHeight: 800,
      }}
    >
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.News}
      </Typography.Title>
      <Row gutter={[62, 42]}>
        {news?.map((newsItem, index) => {
          return (
            <div className="col-12 px-31" key={index}>
                <NewsCardComponent news={newsItem} />
       
            </div>
          );
        })}
      </Row>
      <div className="space71 about-us-event" />
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.Events}
      </Typography.Title>
      {events &&
        events?.map((event, index) => {
          return <EventComponent key={index} event={event} />;
        })}
    </div>
  );
}
const useStyles = makeStyles(() => ({
  newsCardStyle: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    boxShadow: "6px 6px 6px #34337940",
    border: "1px solid #E2E2E2",
  },
  TextArea: {
    background: "#313060d1",
    color: "white",
    minHeight: 150,
    width: "100%",
    padding: "20px 10px",
  },
}));
function NewsCardComponent({ news }) {
  const classes = useStyles();
  return (
    <Link to={`/about-us/news/${news.id}`} className="text-decoration-none">
      <Card
        style={{ backgroundImage: `url('${news.image}')`, minHeight: 412 }}
        className={(classes.newsCardStyle, "about-news-img")}
        bodyStyle={{
          background: "#313060d1",
          color: "white",
          minHeight: 150,
          width: "100%",
          padding: "10px 20px",
          position: "absolute",
          bottom: 0,
        }}
      >
        <div>
          <Typography.Paragraph className="text-submain">
            <span className="f-14 fw-500">{news?.title}</span>
          </Typography.Paragraph>
          <Typography.Paragraph className="text-light">
            <span className="f-14 fw-400">{news?.description}</span>
          </Typography.Paragraph>

          <Typography.Paragraph className="text-light f-14 mb-0">
            <DateRangeIcon /> <span className="f-12 fw-400">{news?.date}</span>
          </Typography.Paragraph>
        </div>
      </Card>
    </Link>
  );
}
export default NewsAndEvents;
