import { Container } from "@material-ui/core";
import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Breadcrumb } from "antd";
import { Link, useParams } from "react-router-dom";
import { List, Typography } from "antd";
import { Row, Col } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import StudyAtKIU from "../component/WhyKIU/StudyAtKIU";
import KIUDegree from "./../component/WhyKIU/KIUDegree";
import LeftTabsAccordionStyled from "../component/common/leftTabsAccoridionStyled";
import Media from "react-media";
import strings from "../../Languages/Language";
import Accreditation from "../component/WhyKIU/Accreditation";

const useStyles = makeStyles(() => ({
  activenav: {
    padding: "10px 20px !important",
    "& span": {
      color: "#079751",
      font: "normal normal normal 14px/21px Poppins",
      padding: "0px 6px 0px 6px",
      borderLeft: "3px solid #33a66e",
    },
    "[dir=rtl] & span": {
      borderLeft: "none !important",
      color: "#8CAA9B",
      borderRight: "3px solid #33a66e",
    },
  },
  inActiveNav: {
    padding: "10px 20px !important",
    "& span": {
      color: "#8CAA9B",
    },
  },
  applyNow: {
    marginTop: 15,
    height: 53,
  },
}));
export default function WhyKiu() {
  const data = [
    [strings.WhoshouldstudyatKIU, "/", 1],
    [strings.BenefitofKIUDegree, "/", 2],
    [strings.Accreditation, "/", 3],
  ];
  const { page } = useParams();
  const [pageon, setpageon] = React.useState(page ? page : data[0][2]);
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="xl">
      <Box className="bg-main p-139" marginBottom={3}>
        <div className="m-padding-0">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">{strings.Home}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{strings.WhyKIU}</Breadcrumb.Item>
          </Breadcrumb>
          <Typography.Text className="f-40" style={{ fontWeight: "500" }}>
            {strings.WhyKIU}
          </Typography.Text>
        </div>
        <Row gutter={[44, 8]}>
          <Media
            query="(min-width: 768px)"
            render={() => (
              <Col xs={24} md={8} className="sub-nav">
                <List
                  style={{
                    marginTop: 26,
                    background: "#f3f3f3",
                    paddingRight: "10px",
                    paddingLeft: "1px",
                  }}
                  bordered
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        borderBottom: "0.5px solid rgba(212, 212, 212, 0.7)",
                        minHeight: "50px",
                      }}
                      onClick={() => {
                        setpageon(item[2]);
                      }}
                      className={
                        pageon === item[2]
                          ? classes.activenav
                          : classes.inActiveNav
                      }
                    >
                      <span className="py-0 pl-1">{item[0]}</span>
                    </List.Item>
                  )}
                />
              </Col>
            )}
          />

          {/* responsive */}
          <Media
            query="(max-width: 767px)"
            render={() => (
              <Col xs={24} md={7} className="sub-nav p-0 mt-4">
                <LeftTabsAccordionStyled data={data} setpageon={setpageon} />
              </Col>
            )}
          />

          {/* pages content */}
          <Col xs={24} md={16}>
            <div style={{ marginTop: 26 }}>{getComponent(pageon)}</div>
          </Col>
        </Row>
      </Box>
    </Container>
  );
}
function getComponent(page) {
  const data = [
    [strings.WhoshouldstudyatKIU, "/", 1],
    [strings.BenefitofKIUDegree, "/", 2],
    [strings.Accreditation, "/", 3],
  ];

  switch (page) {
    case data[0][2]:
    case data[0][0]:
      return <StudyAtKIU />;
    case data[1][2]:
    case data[1][0]:
      return <KIUDegree />;
    case data[2][2]:
    case data[2][0]:
      return <Accreditation />;
    default:
      break;
  }
}
