import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { Breadcrumb } from "antd";
import { Link, useParams } from "react-router-dom";
import { List, Typography } from "antd";
import { Row, Col } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { APP_URL } from "../../config";
import Box from "@material-ui/core/Box";
import VisionAndMission from "../component/Aboutus/VisionAndMission";
import Director from "../component/Aboutus/Director";
import BoardOfTrustees from "../component/Aboutus/BoardOfTrustees";
import Faculities from "./../component/Aboutus/Faculities";
import CollegesAndInstitutes from "./../component/Aboutus/CollegesAndInstitutes";
import FAQs from "./../component/Aboutus/FAQs";
import ContactUs from "./../component/Aboutus/ContactUs";
import NewsAndEvents from "./../component/Aboutus/NewsAndEvents";
import LeftTabsAccordionStyled from "../component/common/leftTabsAccoridionStyled";
import Media from "react-media";
import strings from "../../Languages/Language";
import axios from "axios";
import InstitutionAndEvidence from "../component/Aboutus/InstitutionAndEvidence";

const useStyles = makeStyles((rtl) => ({
  activenav: {
    padding: "10px 20px !important",
    "& span": {
      color: "#079751",
      font: "normal normal normal 14px/21px Poppins",
      padding: "0px 6px 0px 6px",
      borderLeft: "3px solid #33a66e",
    },
    "[dir=rtl] & span": {
      borderLeft: "none !important",
      color: "#8CAA9B",
      borderRight: "3px solid #33a66e",
    },
  },
  inActiveNav: {
    padding: "10px 20px !important",
    "& span": {
      color: "#8CAA9B",
    },
  },
}));
function Aboutus() {
  const data = [
    [strings.KIUVisionAndMission, "/", 1],
    [strings.KIUDirectors, "/", 2],
    [strings.BoardofTrustees, "/", 3],
    [strings.Faculties, "/", 4],
    [strings.CollegeAndInstitute, "/", 5],
    [strings.NewsAndEvents, "/", 6],
    [strings.InstitutionAndEvidence, "/", 7],
    [strings.FAQs, "/", 8],
    [strings.ContactUs, "/", 9],
  ];
  const { page } = useParams();
  const [pageon, setpageon] = React.useState(page ? page : data[0][2]);
  const classes = useStyles("rtl");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="xl">
      <Box className="bg-main p-139" marginBottom={0}>
        <div className="m-padding-0">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">{strings.Home}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{strings.Aboutus}</Breadcrumb.Item>
          </Breadcrumb>
          <Typography.Text className="f-40" style={{ fontWeight: "500" }}>
            {strings.Aboutus}
          </Typography.Text>
        </div>
        <Row gutter={[48, 8]}>
          <Media
            query="(min-width: 768px)"
            render={() => (
              <Col xs={24} md={8} className="sub-nav">
                <List
                  style={{
                    marginTop: 26,
                    background: "#F3F3F3",
                    paddingRight: "10px",
                    paddingLeft: "1px",
                  }}
                  bordered
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        borderBottom: "0.5px solid rgba(212, 212, 212, 0.7)",
                        minHeight: "50px",
                      }}
                      onClick={() => {
                        setpageon(item[2]);
                      }}
                      className={
                        pageon === item[2]
                          ? classes.activenav
                          : classes.inActiveNav
                      }
                    >
                      <span className="py-0 pl-1">{item[0]}</span>
                    </List.Item>
                  )}
                />
              </Col>
            )}
          />
          {/* responsive */}
          <Media
            query="(max-width: 767px)"
            render={() => (
              <Col
                xs={24}
                md={8}
                className="sub-nav p-0 mt-4 m-padding-0"
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <LeftTabsAccordionStyled data={data} setpageon={setpageon} />
              </Col>
            )}
          />

          {/* pages content */}

          <Col xs={24} md={16}>
            <div style={{ marginTop: 26 }}>{getComponent(pageon)}</div>
          </Col>
        </Row>
      </Box>
      <>{pageon === data[0][2] && <Accreditation />}</>
    </Container>
  );
}
function getComponent(page) {
  const data = [
    [strings.KIUVisionAndMission, "/", 1],
    [strings.KIUDirectors, "/", 2],
    [strings.BoardofTrustees, "/", 3],
    [strings.Faculties, "/", 4],
    [strings.CollegeAndInstitute, "/", 5],
    [strings.NewsAndEvents, "/", 6],
    [strings.InstitutionAndEvidence, "/", 7],
    [strings.FAQs, "/", 8],
    [strings.ContactUs, "/", 9],
  ];

  switch (parseInt(page)) {
    case data[0][2]:
    case data[0][0]:
      return <VisionAndMission />;

    case data[1][2]:
    case data[1][0]:
      return <Director />;

    case data[2][2]:
    case data[2][0]:
      return <BoardOfTrustees />;

    case data[3][2]:
    case data[3][0]:
      return <Faculities />;

    case data[4][2]:
    case data[4][0]:
      return <CollegesAndInstitutes />;

    case data[5][2]:
    case data[5][0]:
      return <NewsAndEvents />;

    case data[6][2]:
    case data[6][0]:
      return <InstitutionAndEvidence />;

    case data[7][2]:
    case data[7][0]:
      return <FAQs />;

    case data[8][2]:
    case data[8][0]:
      return <ContactUs />;

    default:
      break;
  }
}

export default Aboutus;

function Accreditation() {
  const [futureData, setFutureData] = useState({});
  // eslint-disable-next-line
  const [error, setError] = useState(null);

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/future`)
        .then((response) => {
          setFutureData(response.data);
        })
        .catch((err) => {
          setError("something went wrong");

          setTimeout(() => {
            setError("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <div className="bg-main p-139" style={{ marginTop: 42 }}>
      <Box className="bg-main future m-padding-0 pl-295">
        <Typography.Title className="f-30 fw-500">
          {strings.Future}
        </Typography.Title>
        <div
          className="f-15 fw-400 ql-editor"
          dangerouslySetInnerHTML={{
            __html: futureData?.description,
          }}
        />
        <br />
        <br />
        <br />
        <div className="row">
          {futureData &&
            futureData.images?.map((image, index) => {
              return (
                <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4" key={index}>
                  <div
                    className="about-future-img bg-default"
                    style={{
                      backgroundImage: `url('${image?.image}')`,
                      width: '100%',
                      height:'30vh'
                    }}
                  />

                </div>
              );
            })}
        </div>
      </Box>
    </div>
  );
}
