import React, { useEffect, useState } from "react";
import { Typography, Table } from "antd";
import strings from "../../../Languages/Language";
import axios from "axios";
import { APP_URL } from "./../../../config";

function OnlineFreeCourses() {
  const [freeCoursesData, setFreeCoursesData] = useState([]);
  const [about, setAbout] = useState({});

  const [error, seterror] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const init = () => {
      axios
        .get(`${APP_URL}/freecourses`)
        .then((res) => {
          setFreeCoursesData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    const init2 = () => {
      axios
        .get(`${APP_URL}/freecourses/about`)
        .then((res) => {
          setAbout(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
    init2();
  }, []);

  const columns = [
    {
      title: strings.Courses,
      key: "course_name",
      render: (text, row) => (
          <>
            <Typography.Title style={{ fontSize: 14 }}>
              {row.course_name}
            </Typography.Title>
            <Typography.Paragraph style={{ fontSize: 12, marginBottom: 0, }}>
              {row.lecturer}
            </Typography.Paragraph>
          </>
      ),
    },
    {
      title: strings.AccessLink,
      dataIndex: "access_link",
      key: "access_link",
      render: (text) => (
        <a href={text} target="_blank">{strings.ClickHere}</a>
      ),
    },
  ];

  return (
    <>
      {error && <div>{error}</div>}
      <div className="online-free-course">
        <Typography.Title style={{ fontSize: 20 }}>
          {strings.KIUFreeCourseLectures}
        </Typography.Title>
        <div className={"ql-editor"}
          dangerouslySetInnerHTML={{
            __html: about?.about,
          }}
        ></div>
        <div>
          <Table
            pagination={false}
            columns={columns}
            dataSource={freeCoursesData}
            className="online-free-course"
            style={{ border: "1px solid #C7C7C7" }}
          />
        </div>
      </div>
    </>
  );
}

export default OnlineFreeCourses;
