import { Card, Typography, Row, Col } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import strings from "../../../Languages/Language";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { useParams } from "react-router-dom";
const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 120,
    height: 3,
    backgroundColor: "#DDDCE9",
    marginTop: 10,
  },
  VisionSpace: {
    marginTop: 20,
  },
  creditHours: {
    color: "#079751",
    textAlign: "center",
  },
  syllabusHeader: {
    border: "1px solid #E5E5E5",
  },
  syllabusLabel: {
    width: "172px",
    height: "39px",
    background: "#313060 0% 0% no-repeat padding-box",
    border: "1px solid #313060",
    textAlign: "center",
    fontSize: 14,
    padding: "10px 35px ",
    color: "white",
  },
}));
function SyllabusBySemester() {
  const [sylabusData, setSylabusData] = useState({});
  const [error, seterror] = useState(null);

  const { page } = useParams();
  const classes = useStyles();
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/degrees/${page}`)
        .then((res) => {
          setSylabusData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, [page]);
  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <Typography.Title style={{ fontSize: 20 }}>
          {strings.SyllabusBySemester}
        </Typography.Title>
        <div className={classes.VisionSpace} />
        <div className="row">
          {sylabusData?.semesters?.map((course, index) => {
            return (
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                <Syllabus index={index} course={course} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function Syllabus({ course, index }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.syllabusLabel}>
        {index + 1} {strings.Semester}
      </div>
      <Card bodyStyle={{ padding: "0 " }} className="SyllabusCard">
        <Row
          style={{
            backgroundColor: "#F9F9F9",
            width: "100%",
            padding: "6px 20px 6px 20px",
          }}
        >
          <Col xs={12} md={14} className="px-0">
            <Typography.Paragraph className="text-submain">
              <span className="f-12 fw-500">{strings.Courses}</span>
            </Typography.Paragraph>
          </Col>
          <Col xs={12} md={10}>
            <Typography.Paragraph className={classes.creditHours}>
              <span className="f-12 fw-500">{strings.CreditHours}</span>
            </Typography.Paragraph>
          </Col>
        </Row>
        <Divider className="pt-0 mt-0" />

        {course?.syllabus?.map((syl, ind) => {
          return (
            <Row
              style={{
                width: "100%",
                padding: "0 0px 0 20px",
                whiteSpace: "nowrap",
              }}
              key={ind}
            >
              <Col xs={12} md={14}>
                <Typography.Paragraph>{syl.courses}</Typography.Paragraph>
              </Col>
              <Col xs={12} md={10}>
                <Typography.Paragraph className="text-center">
                  {syl.credit_hours}
                </Typography.Paragraph>
              </Col>
            </Row>
          );
        })}

        <Divider />
      </Card>
    </div>
  );
}

export default SyllabusBySemester;
