import React, { useEffect, useState } from "react";
import { APP_URL } from "../../../config";
import { Breadcrumb, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import strings from "../../../Languages/Language";
import axios from "axios";

function InstitutionAndEvidenceDetails() {
  const [error, setError] = useState(null);
  const [institutionEvidenceContentData, setInstitutionEvidenceContentData] =
    useState([]);

  const [title, setTitle] = useState("");

  const { id } = useParams();

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${APP_URL}/institution-evidence/${id}`
        );

        setInstitutionEvidenceContentData(
          response?.data?.institutionEvidenceContent
        );
        setTitle(response?.data?.institutionEvidenceTitle);
      } catch (err) {
        setError("Something went wrong");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-main p-139 m-padding-0">
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">{strings.Home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about-us">{strings.Aboutus}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about-us/7">{strings.InstitutionAndEvidence}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="space21" />

      <Typography.Paragraph>
        <Link to="/about-us/7">
          <ArrowBackIosIcon
            className="text-submain"
            style={{ fontSize: 20, margin: "auto 10px" }}
          />
        </Link>
        <span
          className="f-30 fw-500"
          style={{ verticalAlign: "middle", paddingLeft: 10 }}
        >
          {title}
        </span>
      </Typography.Paragraph>

      <div className="my-4 row institution-evidence-tab">
        {institutionEvidenceContentData.map((content) => {
          return (
            <div
              key={content?.key ?? content?.id}
              className="col-12 col-lg-4 my-3 mb-4 d-flex justify-content-center f-14 fw-500 text-center"
            >
              <a href={content?.file} target="_blank">
                <button className="btn">{content?.title}</button>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InstitutionAndEvidenceDetails;
