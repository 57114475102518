import React, { useEffect, useState } from "react";
import { Typography, Card, Row, Col } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import strings from "../../../Languages/Language";
import axios from "axios";
import { APP_URL } from "./../../../config";

function StudyOverview() {
  const [studyOverview, setOverview] = useState({ overview: [], summary: {} });
  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/overview`)
        .then((res) => {
          setOverview(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <div>
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.StudyOverview}
      </Typography.Title>
      <div
        className="fw-400 ql-editor"
        dangerouslySetInnerHTML={{
          __html: studyOverview.summary?.overview,
        }}
      >
        {}
      </div>
      <br />
      <br />
        <Typography.Title style={{ fontSize: 20 }}>
            {strings.InSummaryEachModuleComprisesOf}
        </Typography.Title>
      <div
          className={"ql-editor"}
        style={{ fontSize: 20 }}
        dangerouslySetInnerHTML={{
          __html: studyOverview.summary?.summary,
        }}
      ></div>
      <br />
      <br />
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.CurrentlySemester1IsMadeOf}
      </Typography.Title>
      <Syllabus Subjects={studyOverview.overview} />
    </div>
  );
}
const useStyles = makeStyles(() => ({
  VisionSpace: {
    marginTop: 20,
  },
  Card: {
    background: "transparent",
    border: 0,
    // paddingRight: 170,
    "@media (max-width : 576px)": {
      paddingRight: 0,
    },
    "& .ant-row": {
      padding: 10,
    },
  },
  isRTLPaddingRight: {
    paddingRight: 170,
  },
  creditHours: {
    textAlign: "center",
    fontWeight: 500,
  },
  syllabusHeader: {
    border: "1px solid #E5E5E5",
  },
  syllabusLabel: {
    width: "172px",
    height: "39px",
    background: "#313060 0% 0% no-repeat padding-box",
    border: "1px solid #313060",
    textAlign: "center",
    fontSize: 14,
    padding: "10px 35px ",
    color: "white",
  },
}));
function Syllabus({ Subjects }) {
  const classes = useStyles();
  return (
    <>
        {/*Heading*/}
        <Card bodyStyle={{ padding: "10px 0px" }} className={classes.Card}>
            <div>
                <Row>
                    <Col xs={12} md={18}>
                        <Typography.Paragraph className="text-submain f-14 fw-500">
                            {strings.Subjects}
                        </Typography.Paragraph>
                    </Col>
                    <Col xs={12} md={6}>
                        <Typography.Paragraph className="text-submain f-14 fw-500">
                            {strings.CreditHours}
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </div>
        </Card>
        <Card bodyStyle={{ padding: "10px 0px" }} className={classes.Card}>
            {Subjects &&
              Subjects.map((sub, index) => {
                return (
                  <div key={index}>
                    <Row>
                      <Col xs={12} md={14}>
                        <Typography.Paragraph className="f-14 fw-500 mb-06">
                          {sub.course}
                        </Typography.Paragraph>
                          {sub.subject}
                      </Col>
                      <Col xs={12} md={10}>
                        <Typography.Paragraph className={classes.creditHours}>
                          {sub.credit_hours}
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                );
              })}
        </Card>
    </>
  );
}
export default StudyOverview;
