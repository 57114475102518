import React, { createContext, useContext } from "react";
import strings from "../Languages/Language";

const LanguageContext = createContext();
export const useLanguage = () => useContext(LanguageContext);

export default function UserProvider(props) {
  const locale = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "ar";

  const [language, setlanguage] = React.useState(locale);
  const [dummy, setdummy] = React.useState(false);

  React.useEffect(() => {
    strings.setLanguage(language);
    // Update lang attribute of HTML tag
    document.documentElement.lang = language;
  }, [language]);

  const isRTL = React.useMemo(() => {
    switch (language) {
      case "en":
        return false;
      case "ar":
        return true;
      case "ur":
        return true;
      case "fr":
        return false;
      default:
        return false;
    }
  }, [language]);

  const updateLanguage = (current) => {
    strings.setLanguage(current);
    setlanguage(current);
    localStorage.setItem("locale", current);
    window.location.reload();
    setdummy(!dummy);
  };

  return (
    <LanguageContext.Provider value={{ language, updateLanguage, isRTL }}>
      {props?.children}
    </LanguageContext.Provider>
  );
}
