import React from "react";
// import LoaderGif1 from "../../../assets/loader/loader-1.gif";
// import LoaderGif2 from "../../../assets/loader/loader-2.gif";
// import LoaderGif3 from "../../../assets/loader/loader-3.gif";
import Loader from "../../../assets/loader/loader.gif";

export default function FullPageLoader({loading}) {
  // React.useEffect(() => {
  //   if (loading)
  //     setTimeout(() => {
  //       setisStart(false);
  //     }, 1200);

  //   if (!loading) {
  //     setTimeout(() => {
  //       setisEnd(true);
  //     }, 1030);
  //   }
  // }, [loading]);
  // const [isStart, setisStart] = React.useState(true);
  // const [isEnd, setisEnd] = React.useState(false);
  // const [end, setend] = React.useState(false);
  // const Loader = () => {
  //   return isStart ? LoaderGif1 : isEnd ? LoaderGif3 : LoaderGif2;
  // };
  // if (isEnd) {
  //   setTimeout(() => {
  //     if (isEnd && !end) setend(true);
  //   }, 1250);
  //   return end ? (
  //     <></>
  //   ) : (
  //     <div
  //       className="loader-container"
  //       style={{
  //         zIndex: 9999,
  //         width: "100%",
  //         height: "100%",
  //         maxWidth: "100%",
  //         backgroundColor: "#f4f7f9ab",
  //       }}
  //     >
  //       <div className="loader" style={{ display: "block" }}>
  //         <img src={LoaderGif3} alt="KIU" style={{ display: "block" }} />
  //       </div>
  //     </div>  //   );
  // }

  if (!loading) return <></>;
  return (
    <div
      className='loader-container'
      style={{
        zIndex: 10000,
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        backgroundColor: "#f4f7f9FA",
      }}
    >
      <div className='loader' style={{display: "block"}}>
        <img src={Loader} alt='KIU' style={{display: "block"}} />
      </div>
    </div>
  );
}
