import React, {useEffect} from "react";
import Image404 from "../../../assets/images/404.svg";
import {Link} from "react-router-dom";

const style = {
  color: "white",
  height: "100vh",
  width: "100vw",
  background: "white",
};

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='position-relative not-found' style={style}>
      <img src={Image404} alt='' />
      <div className='not-found-content'>
        <h1>Oops!</h1>
        <h1>page not found</h1>
        <p className='mb-5 mt-2'>
          Sorry we could not find the page you are looking for
        </p>
        <Link to='/' className='text-decoration-none'>
          <span className='go-to-button'> Go to home page</span>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
