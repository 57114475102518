import strings from "../../../Languages/Language";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
const Message = () => {
  const [messageData, setMessageData] = useState({});
  const [error, seterror] = useState(null);

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/director`)
        .then((response) => {
          setMessageData(response.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <>
      {error && <div>{error}</div>}
      {messageData?.message && <div className="w-100 home-message d-flex justify-content-center home-bg-light py-5">
        <div className="home-message-container d-flex justify-content-center">
          <div className=" row g-0 m-md-5 home-message-card">
            <div className="col-md-4 col-12  my-3 my-md-0 ">
              <img
                  src={messageData?.image}
                  alt="..."
                  style={{width: "inherit"}}
              />
            </div>
            <div className="col-md-8 col-12">
              <div className="card-body">
                <h5 className="card-title f-20 fw-500 text-light">
                  {strings.MessageFromDirector}
                </h5>
                <p className="card-text card-text-name d-inline-block">
                  <span className="f-14 fw-500">
                    {messageData?.first_name +
                    " " +
                    messageData?.last_name}
                  </span>
                </p>
                <br/>
                <small className="card-text f-15 fw-400">
                  <div
                      className={"ql-editor text-white"}
                      dangerouslySetInnerHTML={{
                        __html: messageData?.message,
                      }}
                  />
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Message;
