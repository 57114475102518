import React, {useEffect, useState} from "react";
import {Col, Row, Typography} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import strings from "../../../Languages/Language";
import axios from "axios";

import {APP_URL} from "../../../config";

const useStyles = makeStyles(() => ({
    spacing49: {
        marginTop: 49,
    },
}));

function CoursesComponent() {
    // eslint-disable-next-line
    const [error, seterror] = useState(null);

    const [courseData, setCoursesData] = useState({});

    const classes = useStyles();
    useEffect(() => {
        const init = () => {
            axios
                .get(`${APP_URL}/course`)
                .then((res) => {
                    setCoursesData(res.data);
                })
                .catch((err) => {
                    seterror("something went wrong");
                    setTimeout(() => {
                        seterror("");
                    }, 2000);
                });
        };
        init();
    }, []);

    const data = [
        {
            number: courseData?.video_lectures,
            title: strings.VideoLectures,
            description: courseData?.video_lecture_text,
            color: "#313060",
            textColor: "#FFFFFF",
            divColor: "blue",
        },
        {
            number: courseData?.live_sessions,
            title: strings.LiveSessions,
            description: courseData?.live_sessions_text,
            color: "#F57506",
            textColor: "#000",
            divColor: "orange",
        },
        {
            number: courseData?.course_material,
            title: strings.CourseMaterial,
            description: courseData?.course_material_text,
            color: "#079751",
            textColor: "#FFFFFF",
            divColor: "green",
        },
        {
            number: courseData?.final_exam,
            title: strings.FinalExam,
            description: courseData?.final_exam_text,
            color: "#FFFFFF",
            textColor: "#000",
            divColor: "white",
        },
        {
            number: courseData?.discussion_forum,
            title: strings.DiscussionForum,
            description: courseData?.discussion_forum_text,
            color: "#FFFFFF",
            textColor: "#000",
            divColor: "white",
        },
        {
            number: courseData?.assignments,
            title: strings.Assignments,
            description: courseData?.assignments_text,
            color: "#FFFFFF",
            divColor: "white",
            textColor: "#000",
        },
    ];
    return (
        <div>
            <Typography.Title style={{fontSize: 20, fontWeight: 500}}>
                {strings.StudyACourse}
            </Typography.Title>
            <Typography.Paragraph>
                <div
                    className={"ql-editor"}
    dangerouslySetInnerHTML={{
        __html: courseData?.about,
    }}
    />
            </Typography.Paragraph>
            <div className={classes.spacing49}/>
            <Typography.Title style={{fontSize: 20, fontWeight: 500}}>
                {strings.CourseIncludes}
            </Typography.Title>
            <Row gutter={[45, 24]}>
                {data.map((d, index) => {
                    return (
                        <Col xs={24} md={8}>
                            <CourseCard value={d} key={index}/>
                        </Col>
                    );
                })}
            </Row>
            <div className={classes.spacing49}/>
            <div className={classes.spacing49}/>
            <Typography.Paragraph>
                <div
                    className={"ql-editor"}
                    dangerouslySetInnerHTML={{
                        __html: courseData?.bottom_text,
                    }}
                />
            </Typography.Paragraph>
        </div>
    );
}

function CourseCard({value}) {
    return (
        <div className={`triangle-${value.divColor}`}>
            <Typography.Title
                style={{whiteSpace: "nowrap"}}
                className="text-center f-24  fw-600 "
            >
                {value.number}
            </Typography.Title>
            <Typography.Paragraph
                className="text-center"
                style={{color: "#079751"}}
            >
                <span className="text-center f-16 fw-500"> {value.title}</span>
            </Typography.Paragraph>
            <div
                className="text-center f-14 fw-300 position-relative courses-includes"
                style={{
                    position: "absolute",
                    zIndex: 5,
                }}
            >
                <div
                    style={{
                        background: value.color,
                        color: value.textColor,
                        minHeight: 90,
                        padding: 8,
                        boxShadow: "0px 3px 6px #0000000F",
                        // border: "1px solid #DFDFDF",
                    }}
                    className={"ql-editor"}
                    dangerouslySetInnerHTML={{
                        __html: value?.description,
                    }}
                >
                    {}
                </div>
            </div>
        </div>
    );
}

export default CoursesComponent;
