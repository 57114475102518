import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import { useLanguage } from "../../../Context/LanguageContext";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../config";
// import Texty from "rc-texty";
import { useLoading } from "../../../Context/LoadingContext";

const useStyles = makeStyles(() => ({
  media: {
    width: "100%",
    height: "620px",
    position: "relative",
    zIndex: "-1",
  },
  Carousel: {
    "& .slick-dots": {
      bottom: 40,
      "& li button::before": {
        fontSize: 12,
        color: "white",
        opacity: 1,
      },
      "& .slick-active button::before": {
        color: "#079751",
      },
    },
    "& .slick-next": {
      // right: 150,
      // left: 120,
      width: 62,
      height: 62,
      top: "50%",
      transform: "translateY(-50%)",
      "&::before": {
        fontSize: "0px !important",
        opacity: 0,
      },
    },
    "& .slick-prev": {
      // language position issue
      zIndex: 1,
      width: 62,
      height: 62,
      top: "50%",
      transform: "translateY(-50%)",
      "&::before": {
        opacity: 0,
        fontSize: "0px !important",
      },

      ".slick-prev:hover:before": {
        opacity: 1,
      },
    },
  },
}));

function Carousel() {
  const { isRTL } = useLanguage();
  const [carouselData, setCarouselData] = useState({
    about: "",
    carosal_data: [],
  });
  const [error, seterror] = useState(null);
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: isRTL,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    lazyLoad: "ondemand",
  };

  const classes = useStyles();
  const { isPageLoadingState } = useLoading();

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/home/content`)
        .then((res) => {
          console.log("test:", res);
          setCarouselData(res.data);
        })
        .catch(() => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);

  return (
    <>
      {error && <div>{error}</div>}
      {carouselData?.carosal_data.length && (
        <div dir="ltr">
          <Slider
            {...settings}
            className={clsx(
              classes.Carousel,
              isRTL ? "carousel-rtl" : "carousel-ltr"
            )}
          >
            {carouselData?.carosal_data?.map((carItem, index) => (
              <div
                key={index}
                style={{ position: "relative" }}
                className="d-flex justify-content-center carousel-content"
              >
                <div
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${carItem.image})`,
                  }}
                  className={classes.media}
                />
                <div className="slick-carousel-info">
                  {/*<Texty*/}
                  {/*  type={"scale"}*/}
                  {/*  mode={"random"}*/}
                  {/*  style={{*/}
                  {/*    fontSize: 50,*/}
                  {/*    fontWeight: 700,*/}
                  {/*    textAlign: "center",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {!isPageLoadingState &&*/}
                  {/*    "Knowledge International University"}*/}
                  {/*</Texty>*/}
                  {!isPageLoadingState && (
                    <div
                      dangerouslySetInnerHTML={{ __html: carItem?.image_text }}
                      style={{
                        fontSize: 37,
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    />
                  )}
                  {/*{!isPageLoadingState && (*/}
                  {/*  <div className="tagline">جامعة المعرفة الدولية</div>*/}
                  {/*)}*/}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}

function CarouselNextArrow(props) {
  const { isRTL } = useLanguage();

  const { className, onClick } = props;
  return (
    <div
      style={{
        display: "block",
      }}
      className={className}
      onClick={onClick}
    >
      <div className="next-arrow-carousel">
        <div className="carousel-arrow">
          {isRTL ? (
            <i className="material-icons change-direction">
              keyboard_arrow_right
            </i>
          ) : (
            <i className="material-icons">keyboard_arrow_right</i>
          )}
        </div>
      </div>
    </div>
  );
}

function CarouselPrevArrow(props) {
  const { isRTL } = useLanguage();

  const { className, onClick } = props;
  return (
    <div
      style={{
        display: "block",
      }}
      className={className}
      onClick={onClick}
    >
      <div className="next-arrow-carousel">
        <div className="carousel-arrow">
          asd
          {isRTL ? (
            <i className="material-icons ">keyboard_arrow_right</i>
          ) : (
            <i className="material-icons change-direction">
              keyboard_arrow_right
            </i>
          )}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
