const HomeTabContent = ({tabContent}) => {
    return (
        <div className="w-100 d-flex justify-content-center home-tab-content">
            <div className="w-50">
                <div className="my-5">
                    <p className="f-15 fw-400">{tabContent.buttons[0]?.from ? tabContent.description + tabContent.buttons[0]?.from + '-' + tabContent.buttons[0]?.to : tabContent.description}</p>
                    <div className="my-3 row justify-content-center">
                        {tabContent.buttons.map((button) => {
                            return (
                                <div
                                    key={button?.id ?? button?.key}
                                    className="col-12 col-lg-4 my-3 mb-4 d-flex justify-content-center f-14 fw-500 text-center"
                                >
                                    <a href={button?.file ?? button?.url} target="_blank">
                                        <button className="btn">{button?.title ?? button?.label}</button>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Read Me

// pass object containing tab Content:
// Object should contains description and buttons /w its unique key and buttons.
// data i-e : applicationContent: {description: "your desc", buttons: [{key: "1", label: "any label"}, ],},

export default HomeTabContent;
