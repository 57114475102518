import { KeyboardArrowDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import clsx from "clsx";

const useStyles = makeStyles({
  active: {
    color: "#00984f",
    fontWeight: 600,
    paddingLeft: 26,
  },
  inActive: {
    color: "#8CAA9B",
  },
});

const LeftTabsAccordionStyled = ({ data, setpageon }) => {
  const [expanded, setExpanded] = useState(true);
  const [currentPage, setCurrentPage] = useState(data[0][2]);
  const classes = useStyles();

  const handlePageChanged = (item) => {
    setCurrentPage(item[2]);
  };
  const handlePageExpanded = () => {
    setExpanded(expanded ? false : true);
  };
  const handleHeaderClick = () => {
    setExpanded(expanded ? false : true);
  };

  return (
    <div className="left-tab-accordion-styled w-100">
      <div className="d-flex justify-content-center">
        <div className="w-100">
          <div className={expanded ? "heading hideContent" : "heading"}>
            <KeyboardArrowDown
              style={{ fontSize: "60px" }}
              onClick={() => {
                handleHeaderClick();
              }}
            />
          </div>
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  expanded && currentPage !== item[2]
                    ? "content hideContent"
                    : "content  contentBorder"
                }
                onClick={() => {
                  handlePageChanged(item);
                  handlePageExpanded();
                  setpageon(item[2]);
                }}
              >
                <p
                  className={clsx(
                    item[2] === currentPage ? classes.active : classes.inActive,
                    "mb-0 py-2"
                  )}
                >
                  {item[0]}
                </p>
                <KeyboardArrowDown
                  style={{ fontSize: "30px", float: "right" }}
                  className={expanded ? "contentArrowDown" : "hideContent"}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeftTabsAccordionStyled;

// Pass Data and page handler
// data format: {"name of page", "/"}
