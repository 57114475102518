import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Typography } from "antd";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { useParams } from "react-router-dom";
import strings from "../../../Languages/Language.jsx";

const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState([]);

  const [error, seterror] = useState(null);
  let { page } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const init = () => {
      axios
        .get(`${APP_URL}/course-details/${page}`)
        .then((res) => {
          setCourseDetails(res.data);

          console.log(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, [page]);
  return (
    <>
      {error && <div>{error}</div>}
      <Container maxWidth="xl" className="bg-main ">
        <Box className="m-padding-0 p-139" marginBottom={3}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/courses">courses</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/courses/Course Categories">Course Categories</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>courses detail</Breadcrumb.Item>
          </Breadcrumb>
          {/* <Typography.Text className='f-40 fw-500'>{page}</Typography.Text> */}
          <Typography.Paragraph>
            <Link to="/courses/Course Categories">
              <ArrowBackIosIcon
                className="text-submain"
                style={{ fontSize: 20 }}
              />
            </Link>
            <span
              className="f-40 fw-500"
              style={{ verticalAlign: "middle", paddingLeft: 10 }}
            >
              {courseDetails.course_title}
            </span>
          </Typography.Paragraph>

          {/* ///////////////////////// content /////////////////////////  */}
          <div
            style={{ fontWeight: "500" }}
            className="mt-4 course-details-content"
          >
            <div className="mb-4">
              <h1 className=" "> </h1>
              <p>{courseDetails.course_title}</p>
            </div>
            <div className="d-flex justify-content-between course-details mb-4 flex-wrap">
              <div className="w-nowrap fw-500">
                <h1 className=" fw-500"> Credit Hours</h1>
                <p>{courseDetails.credit_hours}</p>
              </div>

              <div className="w-nowrap fw-500">
                <h1 className=" fw-500">{strings.Instructors}</h1>
                <p>{courseDetails.instructor}</p>
              </div>

              <div className="w-nowrap fw-500">
                <h1 className="fw-500">{strings.Required}</h1>
                <hasPrefixSuffix className="f-15">
                  {courseDetails.required}
                </hasPrefixSuffix>
              </div>
            </div>
            {/* description */}
            <div className="mb-4">
              <h1>{strings.Description}</h1>
              <p
                  className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: courseDetails.description,
                }}
              ></p>
            </div>
            {/* Objectives */}
            <div className="mb-4">
              <h1>{strings.Objectives}</h1>
              <p
                  className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: courseDetails.objectives,
                }}
              ></p>
            </div>
            {/* topic to cover */}
            <div className="mb-4">
              <h1>{strings.TopicsToCover}</h1>

              <p
                  className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: courseDetails.topics,
                }}
              ></p>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default CourseDetails;
