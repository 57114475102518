import { Typography } from "antd";
import strings from "../../../Languages/Language";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
function AcademicHelp() {
  const [academicData, setAcademicData] = useState([]);
  const [error, seterror] = useState(null);

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/degree/academichelp`)
        .then((res) => {
          setAcademicData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <Typography.Title style={{ fontSize: 20, fontWeight: 500 }}>
          {strings.AcademicHelp}
        </Typography.Title>
          <div
              className={"ql-editor"}
            dangerouslySetInnerHTML={{
              __html: academicData.about,
            }}
          ></div>
      </div>
    </>
  );
}

export default AcademicHelp;
