import React, { useEffect, useState } from "react";
import { Typography, Card } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import strings from "../../../Languages/Language";
import axios from "axios";
import { APP_URL } from "./../../../config";

function CourseCategories() {
  const [courseCategoriesData, setCourseCategoriesData] = useState([]);
  const [error, seterror] = useState(null);
  const [about, setAbout] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    const init = () => {
      axios
        .get(`${APP_URL}/category`)
        .then((res) => {
          setCourseCategoriesData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };

    const init2 = () => {
      axios
        .get(`${APP_URL}/category/about`)
        .then((res) => {
          setAbout(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
    init2();
  }, []);

  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <Typography.Title style={{ fontSize: 20 }}>
          {strings.CourseCategories}
        </Typography.Title>
        <div
            className={"ql-editor"}
            dangerouslySetInnerHTML={{
              __html: about.about,
            }}
        />
        <br />

        <Typography.Title style={{ fontSize: 20 }}>
          {strings.CourseCatalogue}
        </Typography.Title>
        {courseCategoriesData?.map((c, index) => {
          return <CourseCatalogue key={index} value={c} />;
        })}
      </div>
    </>
  );
}
const CourseCatalogueStyles = makeStyles(() => ({
  courseRow: {
    border: "1px solid #D5D5D5",
    padding: "13px 30px",
    fontSize: 18,
    margin: "0px !important",
  },
  courseHeadRow: {
    background: "#343379e3 0% 0% no-repeat padding-box",
    padding: "13px 30px",
    fontSize: 18,
    color: "white",
  },
}));
function CourseCatalogue({ value }) {
  const classes = CourseCatalogueStyles();
  return (
    <>
      <Card bodyStyle={{ padding: 0 }}>
        <div
          className="course-catelogue-header"
          style={{ backgroundImage: `url('${value.image}')` }}
        >
          <Typography.Paragraph className={classes.courseHeadRow}>
            <span className="f-25 fw-500"> {value.title}</span>
          </Typography.Paragraph>
        </div>
        {value.courses?.map((c, index) => {
          return (
            <Link
              to={`/course-details/${c.id}`}
              style={{ textDecoration: "none" }}
              key={index}
            >
              <div className="course-categories-list-item">
                <p key={index} className={classes.courseRow}>
                  {c?.course_title}
                </p>
              </div>
            </Link>
          );
        })}
      </Card>
    </>
  );
}
export default CourseCategories;
