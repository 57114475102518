import React, { useEffect, useLayoutEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import { APP_URL } from "../../config";
import { useLoading } from "../../Context/LoadingContext";
import strings from "../../Languages/Language";
import Box from "@material-ui/core/Box";
import axios from "axios";

const Index = () => {
  const [error, setError] = useState(null);
  const { setIsPageLoading } = useLoading();
  const [institutionEvidenceData, setInstitutionEvidenceData] = useState([]);

  useLayoutEffect(() => {
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, []);

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APP_URL}/institution-evidence`);
        setInstitutionEvidenceData(response?.data?.institutionEvidence);
      } catch (err) {
        setError("Something went wrong");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    };

    fetchData();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box className="bg-main-different" marginBottom={0}>
        <div className="institution-evidence-page">
          <div className="hero-section">
            <Typography.Text
              className="f-40 text-white"
              style={{ fontWeight: "500" }}
            >
              {strings.InstitutionAndEvidence}
            </Typography.Text>
          </div>

          {error && <div>{error}</div>}

          <div className="institution-evidence-content">
            <div className="container">
              <div className="row">
                {institutionEvidenceData?.map((content) => {
                  return (
                    <div
                      key={content?.key ?? content?.id}
                      className="col-12 col-lg-4 col-md-6"
                    >
                      <Link to={`/institution-evidence/${content?.id}`}>
                        <button className="btn">{content.title}</button>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default Index;
