import React, { useEffect } from "react";
import { useLoading } from "../Context/LoadingContext";
import Carousel from "../pages/component/Home/Carousel";
import ApplicationProcess from "../pages/component/Home/ApplicationProcess";
import NewsCard from "../pages/component/common/NewsCard";
import Events from "../pages/component/Home/Events";
import AboutUs from "../pages/component/Home/AboutUs";
import MessageCard from "../pages/component/Home/Message";
import Statistics from "./component/Home/Statistics";
import Testimonial from "./component/Home/Testimonial";

function Home() {
  const { setIsPageLoading } = useLoading();

  // use effect
  useEffect(() => {
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {/* Carousel */}
      <Carousel />

      {/* Home Body*/}
      <ApplicationProcess />

      <div className="p-l139-r42">
        {/* ////////////////////// About Us ////////////////////// */}
        <AboutUs />

        {/* ////////////////////// Message Card ////////////////////// */}
        <MessageCard />

        {/* ////////////////////// KIU Statistics ////////////////////// */}
        <Statistics />

        {/* ////////////////////// News ////////////////////// */}
        <NewsCard />

        {/* ////////////////////// Events ////////////////////// */}
        <Events />

        {/* ////////////////////// Testimonials ////////////////////// */}
        <Testimonial />
      </div>
    </>
  );
}

export default Home;
