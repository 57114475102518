import { Link } from "react-router-dom";
import strings from "../../../Languages/Language";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import moment from "moment";

const Events = () => {
  const [eventsData, setEventsData] = useState([]);
  const [error, seterror] = useState(null);

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/events`)
        .then((res) => {
          setEventsData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  if (eventsData.length === 0) return <div />;
  return (
    <div className="w-100 d-flex justify-content-center events  news home-bg-light py-md-5 py-2 ">
      {error && <div>{error}</div>}
      <div className="w-100  w-responsive-10">
        <div className="w-100">
          <ul className="list-inline news-heading ">
            <li className="list-inline-item">
              <h2 className="m-0 mr-2 f-40 fw-400">{strings.Events}</h2>
            </li>
            <li className="list-inline-item f-14 fw-400">
              <Link to="/about-us/6">
                <span className="py-1">{strings.ViewAll}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-100">
          <div className="row mx-0  w-auto justify-content-between events-container">
            {eventsData &&
              eventsData?.map((item, index) => {
                return (
                  <div
                    className="col-12 col-md-6 px-0 px-md-1 p-0 my-2"
                    key={index}
                  >
                    <EventComponent event={item} key={index} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export function EventComponent({ event }) {
  return (
    <div className="row mx-0 justify-content-between date-vertical-card-container space15">
      <div className="col-md-3 col-lg-2 col-sm-4 date-vertical-card d-flex justify-content-center ">
        <div className="text-center">
          <i className="material-icons">date_range</i>
          <p className="my-2 d-block f-13 fw-500">
            {moment(event?.date_at).format("YYYY-MM-DD")}
          </p>
          <p className="d-block f-12 fw-500">
            {moment(event?.date_at).format("hh:mm a")}
          </p>
        </div>
      </div>
      <div className="col-md-9 col-lg-10 col-sm-8 p-0 pl-3">
        <div
          className="card-image about-news-img"
          style={{ backgroundImage: `url(${event?.image})`, minHeight: 168 }}
        ></div>
        <div className="card-body pl-0 about-us-card-body">
          <p className="card-title text-submain f-12 fw-500 ">{event?.title}</p>
          <p className="card-text f-15 fw-400">{event?.description} </p>
        </div>
      </div>
    </div>
  );
}
export default Events;
