import React from "react";
import strings from "../../../Languages/Language";
import { Typography } from "antd";

function Accreditation() {
    return (
        <div className="why-kiu-benefits">
            <Typography.Title style={{ fontSize: 20 }}>
                {strings.Accreditation}
            </Typography.Title>
              <Typography.Paragraph>
                  {strings.AccreditationContent}
              </Typography.Paragraph>
        </div>
    );
}

export default Accreditation;
