import { Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import strings from "../../../Languages/Language";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
const useStyles = makeStyles(() => ({
  VisionSpace: {
    marginTop: 26,
  },
}));
function TuitionFees() {
  const classes = useStyles();

  const [tuitionFeeData, setTuitionFeeData] = useState({
    about: "",
    carosal_data: [],
  });
  const [error, seterror] = useState(null);

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/degree/fee`)
        .then((res) => {
          setTuitionFeeData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <Typography.Title style={{ fontSize: 20, fontWeight: 500 }}>
          {strings.TuitionFees}
        </Typography.Title>
        <Typography.Paragraph>
          <div
              className="d-inline-block ql-editor"
              dangerouslySetInnerHTML={{
                __html: tuitionFeeData.tution_fee,
              }}
          />
        </Typography.Paragraph>
        <div className={classes.VisionSpace}></div>

        <Typography.Title style={{ fontSize: 20, fontWeight: 500 }}>
          {strings.CurrentRates}
        </Typography.Title>
        <Box>
          <Typography.Paragraph>
            <span>
              <div
                  className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: tuitionFeeData.current_rates,
                }}
              />
            </span>
          </Typography.Paragraph>
        </Box>
        <div className={classes.VisionSpace}/>

        <Typography.Title style={{ fontSize: 20, fontWeight: 500 }}>
          {strings.ScholarshipEntitlement}
        </Typography.Title>
        <Typography.Paragraph>
          <div
              className={"ql-editor"}
            dangerouslySetInnerHTML={{
              __html: tuitionFeeData.scholarship_entitlement,
            }}
          />
          </Typography.Paragraph>
      </div>
    </>
  );
}

export default TuitionFees;
