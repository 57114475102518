import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { useMediaQuery } from "react-responsive";
import { useLanguage } from "../Context/LanguageContext";
import { useLoading } from "../Context/LoadingContext";
import { differentPageURLs, getCollegeFullName } from "../Constants/globals";
import { APP_URL } from "../config";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import logo from "../assets/logo/logo.png";
import KIUESTLogo from "../assets/logo/kiu-est-logo.png";
import Box from "@material-ui/core/Box";
import App from "../App";
import Collapse from "@material-ui/core/Collapse";
import HeaderImage from "../assets/images/Header.jpg";
import FooterComponent from "./../pages/component/common/FooterComponent";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Media from "react-media";
import strings from "./../Languages/Language";
import axios from "axios";
import FullPageLoader from "../pages/component/common/FullPageLoader";

const { Text } = Typography;
const drawerWidth = 200;
// let numberOfAjaxCAllPending = 0;

export default function Sidebar() {
  const classes = useStyles();
  const { isPageLoadingState } = useLoading();
  const { updateLanguage, language, isRTL } = useLanguage();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const currentPageURL = window.location.pathname;

  const [open, setOpen] = React.useState(!isTabletOrMobile);
  const [collapse, setcollapse] = React.useState(false);
  const [currentLang, setCurrentLang] = React.useState(language);
  const [degree, setDegree] = useState([]);
  const [page, setPage] = React.useState("");
  const [childPage, setChildPage] = React.useState("");
  const [MobileDrawerChange, setMobileDrawerChange] = React.useState(false);
  const [error, setError] = useState(null);
  const [differentPage, setDifferentPage] = useState(false);

  const pages = {
    "about-us": strings.Aboutus,
    "online-degree": strings.OnlineDegree,
    courses: strings.Courses,
    "how-it-works": strings.HowItWorks,
    "admission-and-fee": strings.AdmissionAndFee,
    "why-kiu": strings.WhyKIU,
  };

  const navItems = [
    { name: strings.Home, url: "/", children: null },
    { name: strings.Aboutus, url: "/about-us", children: null },
    {
      name: strings.OnlineDegree,
      children: [{ name: degree.english_name, url: "/online-degree" }],
    },
    { name: strings.Courses, url: "/courses", children: null },
    { name: strings.HowItWorks, url: "/how-it-works", children: null },
    {
      name: strings.AdmissionAndFee,
      url: "/admission-and-fee",
      children: null,
    },
    { name: strings.WhyKIU, url: "/why-kiu", children: null },
  ];

  const locale = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "ar";

  const degree_name = getCollegeFullName[locale] + "_name";

  // Axios intercept logic
  // TODO: refactor interceptors
  axios.interceptors.request.use(
    function (config) {
      // numberOfAjaxCAllPending++;
      // setIsPageLoading(true)
      config.headers.locale = localStorage.getItem("locale") ?? "ar";
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  // axios.interceptors.response.use(function (response) {
  //     numberOfAjaxCAllPending--;
  //     if (numberOfAjaxCAllPending === 0) {
  //         // setTimeout(() => {
  //         //     setIsPageLoading(false)
  //         // }, 2600)
  //     }
  //     return response;
  // }, function (error) {
  //     numberOfAjaxCAllPending--;
  //     if (numberOfAjaxCAllPending === 0) {
  //         // setTimeout(() => {
  //         //     setIsPageLoading(false)
  //         // }, 2600)
  //     }
  //     return Promise.reject(error);
  // });

  // axios end Intercept logic

  // use effect
  useEffect(() => {
    if (differentPageURLs.includes(currentPageURL)) {
      setOpen(false);
      setDifferentPage(true);
    }

    const init = () => {
      axios
        .get(`${APP_URL}/degrees`)
        .then((response) => {
          setDegree(response.data);
        })
        .catch(() => {
          setError("something went wrong");
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    };

    init();
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setMobileDrawerChange(false);
      setOpen(false);
    }

    window.addEventListener("resize", handleResize);
  }, [currentLang]);

  // methods
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleCollapse = () => {
    setcollapse(!collapse);
  };

  const handleResize = () => {
    if (window.innerWidth <= 576) {
      setMobileDrawerChange(true);
    } else {
      setMobileDrawerChange(false);
    }
  };

  const handleSelectLanguage = (current) => {
    setCurrentLang(current);
    updateLanguage(current);
  };

  const routeChange = () => {
    let first = window.location.pathname.split("/")[1];
    setPage(first === "" ? strings.Home : pages[first]);
  };

  return (
    <>
      <FullPageLoader loading={isPageLoadingState} />

      <Router basename="/" onChange={routeChange}>
        <div className={classes.root}>
          <CssBaseline />
          {/* /////////////////////////// APP Bar ///////////////////////////  */}
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            elevation={1}
          >
            <Toolbar className={classes.toolbar}>
              <div>
                <Media
                  query="(min-width: 576px)"
                  render={() => (
                    <Box className={classes.languageSelect}>
                      <SelectLanguage
                        currentLang={currentLang}
                        handleSelectLanguage={handleSelectLanguage}
                      />
                    </Box>
                  )}
                />
              </div>

              {/* KIU Logo */}
              {differentPage ? (
                <Box className={classes.title}>
                  <Link to="/institution-evidence">
                    <img src={KIUESTLogo} width="120" alt="kiu-est-logo" />
                  </Link>
                </Box>
              ) : (
                <Box className={classes.title}>
                  <Link to="/">
                    {isTabletOrMobile ? (
                      <img src={logo} width="180" alt="kiu-logo" />
                    ) : (
                      <img src={logo} width="224" alt="kiu-logo" />
                    )}
                  </Link>
                </Box>
              )}

              {/* Menu Button */}
              {!differentPage && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  className={clsx(classes.kebabMenuColor)}
                  disableFocusRipple
                  disableRipple
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>

          <div className={classes.header} />

          <div
            dir={isRTL ? "rtl" : "ltr"}
            className={`w-100 d-flex justify-content-center ${
              !differentPage && "spacing-bottom-25"
            }`}
          >
            <main
              className={clsx(
                open ? classes.openmain : classes.main,
                classes.content,
                open ? "drawer-open" : "drawer-close",
                {
                  [classes.contentShift]: open,
                }
              )}
            >
              <div className={classes.drawerHeader} />
              <div
                className={clsx(
                  open ? classes.openmenu : "",
                  isRTL && open ? "mobile-float-left" : ""
                )}
              >
                <App setPathname={routeChange} />
              </div>
            </main>
          </div>

          {/* ////////////////////////////  Drawer  ////////////////////////////*/}
          <Drawer
            className={("drawer-container", classes.drawer)}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {/* close icon for mobile version */}
            <div className="drawer-close-phone">
              <MenuIcon
                className="close-icon"
                onClick={() => {
                  return open ? setOpen(false) : "";
                }}
              />
            </div>
            <Divider />

            <Text
              className={clsx(
                classes.navigationLabel,
                isTabletOrMobile ? "mobile-top-spacing" : ""
              )}
            >
              {strings.Navigation}
            </Text>
            <List className="w-100">
              {navItems.map((text, index) =>
                text.url != null ? (
                  <ListItem
                    button
                    key={index}
                    className={clsx(
                      classes.textRight,
                      page === text.name && classes.selectNavItem
                    )}
                  >
                    <ListItemText>
                      <Link
                        to={text.url}
                        className={classes.linkStyle}
                        onClick={() => {
                          setPage(text.name);
                          setcollapse(false);
                          MobileDrawerChange
                            ? handleDrawerOpen(true)
                            : handleResize();
                        }}
                      >
                        <span className="text f-drawer-14 fw-drawer-400">
                          {text.name}
                        </span>
                      </Link>
                    </ListItemText>
                  </ListItem>
                ) : (
                  <div key={index}>
                    <ListItem
                      button
                      onClick={() => {
                        setPage(text.name);
                        handleCollapse();
                      }}
                      style={{ display: "inline-block" }}
                      className={clsx(
                        classes.textRight,
                        collapse && classes.selectChildNavItem,
                        page === childPage && classes.navItemChildActive
                      )}
                    >
                      {/* Online Degree Collapse */}
                      <ListItemText
                        style={{ display: "inline-block" }}
                        className={"text f-14 fw-400 "}
                      >
                        <span className="text f-drawer-14 fw-drawer-400 nowrap">
                          {text.name}
                        </span>
                      </ListItemText>
                      <span>{collapse ? <ExpandLess /> : <ExpandMore />}</span>
                      {/* Icons */}
                    </ListItem>
                    {/* Collapse Dropdown */}
                    <Collapse in={collapse} timeout="auto" unmountOnExit>
                      <List component="div" className="nav-collapse-list-items">
                        {degree.map((child, index) => {
                          return (
                            // List item in dropdown
                            <ListItem
                              button
                              key={index}
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              className={clsx(
                                classes.textRight,
                                page === child.name && classes.selectChildItem
                              )}
                              onClick={() => {
                                setPage(text.name);
                                setChildPage(child.name);
                                MobileDrawerChange
                                  ? handleDrawerOpen(true)
                                  : handleResize();
                              }}
                            >
                              <ListItemText>
                                <Link
                                  to={`/online-degree/${child.id}/${child[degree_name]}`}
                                  className={classes.linkStyle}
                                >
                                  <span className="text mb-0 f-14 fw-300 child-item-hover">
                                    {child[degree_name]}
                                  </span>
                                </Link>
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </div>
                )
              )}
            </List>
            {/* select language for responsive drawer */}
            <SelectLanguage
              currentLang={currentLang}
              handleSelectLanguage={handleSelectLanguage}
            />
          </Drawer>
        </div>
        <Footer className={`footer ${open ? classes.appBarShift : ""} `}>
          <FooterComponent />
        </Footer>
      </Router>
    </>
  );
}

// Language
const SelectLanguage = ({ currentLang, handleSelectLanguage }) => {
  const languages = {
    English: "en",
    عربي: "ar",
    Français: "fr",
    اردو: "ur",
  };

  return (
    <div className="dropdown select-language ">
      <span
        className="btn f-14 text-center ml-4"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
      >
        <div>
          <span>
            {Object.keys(languages).map((m) =>
              languages[m] === currentLang ? m : ""
            )}
          </span>
          <span className="dropdown-toggle mx-2" />
        </div>
      </span>

      <ul className="dropdown-menu select-language-content">
        {Object.keys(languages).map((key, index) => {
          return (
            <li key={index}>
              <p
                className="dropdown-item f-15 text-dark mb-0 cursor-pointer"
                onClick={() => handleSelectLanguage(languages[key])}
              >
                {key}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    backgroundImage: `url(${HeaderImage})`,
    zIndex: -10,
    // backgroundRepeat: "no-repeat",
    // // backgroundAttachment: "fixed",
    // backgroundSize: "100% ",
    // width: "100%",
    // backgroundPosition: "cover",
    position: "fixed",
    display: "block",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  main: {
    // maxWidth: 1366,
  },
  openmain: {
    // maxWidth: `calc(1366px - ${drawerWidth}px)`,
  },
  toolbar: {
    height: "104px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    Color: "#343379",
    height: "104px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 2px 6px rgba(0, 0, 41, 0.08)",
    position: "fixed",
    zIndex: 9999,
    "@media (max-width: 576px)": {
      position: "fixed",
      zIndex: 999,
    },
    border: 0,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "rgba(50, 49, 119, 0.97)",
    backdropFilter: "blur(5px)",
    color: "#918DF6",
    "@media (max-width: 576px)": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      position: "fixed",
      zIndex: 999,
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    marginTop: 15,
  },
  content: {
    paddingTop: 26,
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    marginRight: 0,
  },
  kebabMenuColor: {
    background: "#30306E",
    color: "#FFFFFF",
    borderRadius: "0px",
    marginRight: "-24px",
    height: 36,
    width: 36,
    "&:hover": {
      background: "#30306E",
      color: "#FFFFFF",
    },
  },
  textRight: {
    textAlign: "right",
    paddingRight: 16,
    paddingLeft: 1,
    "& a": {
      textDecoration: "none",
    },
    "@media (max-width: 576px)": {
      textAlign: "center",
      paddingLeft: 1,
      paddingRight: 0,
    },
  },
  textRightCollapse: {
    paddingRight: 16,
  },
  languageSelect: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "#343379",
    fontSize: 14,
    fontWeight: 400,
    "& .ant-select-arrow": {
      color: "#343379",
    },
  },
  menuLanguageSelect: {
    color: "#343379",
  },
  navigationLabel: {
    marginTop: 74,
    background: "#282860",
    height: 17,
    color: "#64649E",
    fontSize: 10,
    paddingRight: 20,
    textAlign: "right",

    "@media (max-width: 576px)": {
      width: "100%",
      textAlign: "center",
      paddingRight: 0,
    },
  },
  selectNavItem: {
    color: "#FFFFFF",
    "& span.text": {
      fontSize: 17,
      fontWeight: 500,
      borderBottom: "1px solid #ffffff",
      paddingBottom: 2,
    },
  },
  selectChildNavItem: {
    color: "#FFFFFF",
    "& span.text": {
      paddingBottom: 2,
      fontWeight: 500,
      fontSize: 17,
    },
  },
  selectChildItem: {
    color: "#FFFFFF",

    "& span.text": {
      paddingBottom: 2,
    },
  },
  navItemChildActive: {
    "& span.text": {
      borderBottom: "1px solid #ffffff",
    },
  },
  linkStyle: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
  openmenu: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: "ease-in 1s width",
  },
}));
