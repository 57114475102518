import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus/Index";
import OnlineDegree from "./pages/OnlineDegree/Index";
import Courses from "./pages/Courses/index";
import HowItWorks from "./pages/HowItWorks/index";
import AdmissionAndFee from "./pages/AdmissionAndFee/index";
import WhyKiu from "./pages/WhyKIU/index";
import NewsDetails from "./pages/component/Aboutus/NewsDetails";
import NotFound from "./pages/component/Errors/Notfound";
import CourseDetails from "./pages/component/Courses/CourseDetails";
import InstitutionAndEvidenceDetails from "./pages/component/Aboutus/InstitutionAndEvidenceDetails";
import InstitutionAndEvidenceDifferent from "./pages/InstitutionAndEvidence/Index";
import InstitutionAndEvidenceDifferentView from "./pages/InstitutionAndEvidence/View";

function App({ setPathname }) {
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location, setPathname]);

  return (
    <div className="p-0">
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about-us/:page?" component={Aboutus} exact />
        <Route path="/about-us/news/:id?" component={NewsDetails} exact />
        <Route
          path="/about-us/institution-evidence/:id"
          component={InstitutionAndEvidenceDetails}
          exact
        />
        <Route
          path="/online-degree/:page/:name?"
          component={OnlineDegree}
          exact
        />
        <Route path="/courses/:page?" component={Courses} exact />
        <Route path="/how-it-works/:page?" component={HowItWorks} exact />
        <Route
          path="/admission-and-fee/:page?"
          component={AdmissionAndFee}
          exact
        />
        <Route path="/why-kiu/:page?" component={WhyKiu} exact />
        {/* course details */}
        <Route
          name="course-detail"
          path="/course-details/:page?"
          component={CourseDetails}
          exact
        />

        {/* This route is for different view for Institution & Evidence Module */}
        <Route
          path="/institution-evidence"
          component={InstitutionAndEvidenceDifferent}
          exact
        />
        <Route
          path="/institution-evidence/:id"
          component={InstitutionAndEvidenceDifferentView}
          exact
        />

        <Route exact path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
