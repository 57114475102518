import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import strings from "../../../Languages/Language";
import axios from "axios";
import { APP_URL } from "./../../../config";
function StudyAtKIU() {
  const [studyAtKiu, setStudyAtKiu] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${APP_URL}/why/study`)
      .then((res) => {
        setStudyAtKiu(res.data);
      })
      .catch((err) => {
        seterror("something went wrong");
        setTimeout(() => {
          seterror("");
        }, 2000);
      });
  }, []);
  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <Typography.Title style={{ fontSize: 20 }}>
          {strings.WhoshouldstudyatKIU}
        </Typography.Title>
        <div
            className={"ql-editor"}
          dangerouslySetInnerHTML={{
            __html: studyAtKiu?.about,
          }}
        />
      </div>
      <div className="space71"/>
    </>
  );
}

export default StudyAtKIU;
