import React from "react";
import { Link } from "react-router-dom";

const VerticalCard = ({ news }) => {
  const newsExceptFirst = news.slice(1, 3);
  const maxLength = 130;
  return (
    <div className="row mx-0 w-sm-v-item vertical-news-card px-2">
      {newsExceptFirst &&
        newsExceptFirst?.map((newsItem, index) => {
          return (
            <Link
              key={index}
              to={`/about-us/news/${newsItem.id}`}
              className="text-decoration-none w-100"
            >
              <div
                className="card w-100"
                style={{
                  marginBottom: "15px",
                  borderRadius: 0,
                  backgroundImage: `url(${newsItem.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPositionX: "right",
                }}
              >
                <div className="card-body card-news">
                  <p className="card-title f-14 fw-500">{newsItem.title}</p>
                  <p className="card-text f-14 fw-400">{`${newsItem.description.substring(
                    0,
                    maxLength
                  )}...`}</p>
                  <div
                    className="d-flex align-items-center mb-3"
                    style={{ position: "absolute", bottom: 0 }}
                  >
                    <i className="material-icons f-20">date_range</i>
                    <span className="mx-4 f-12 fw-400">{newsItem?.date}</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default VerticalCard;
