import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Typography } from "antd";
import strings from "../../../Languages/Language";
function Scholarships() {
  const [scholarship, setScholarship] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${APP_URL}/scholarships`)
      .then((res) => {
        setScholarship(res.data);
      })
      .catch((err) => {
        seterror("something went wrong");
        setTimeout(() => {
          seterror("");
        }, 2000);
      });
  }, []);
  return (
    <div>
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
          {strings.Scholarships}
      </Typography.Title>
      <div
        className="fw-300 ql-editor"
        dangerouslySetInnerHTML={{ __html: scholarship.body }}
      ></div>
    </div>
  );
}

export default Scholarships;
