import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Card, Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import strings from "../../../Languages/Language";

const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 199,
    height: 3,
    backgroundColor: "#343379",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 7,
    marginBottom: 3,
  },
  VisionSpace: {
    marginTop: 51,
  },
  VisionSpace31: {
    marginTop: 31,
  },
  mainBox: {
    boxShadow: "0px 3px 6px #00000038",
    border: "1px solid #EFEFEF",
    background: "white",
    opacity: 1,
  },
  card: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000026",
    border: "1px solid #EFEFEF",
  },
}));
export default function Faculities() {
  const classes = useStyles();
  const [data, setdata] = useState({ arabic: [], nonArabic: [] });
  const [error, seterror] = useState(null);
  const [about, setAbout] = useState({});

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/faculty`)
        .then((res) => {
          setdata(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    const init2 = () => {
      axios
        .get(`${APP_URL}/faculty/about`)
        .then((res) => {
          setAbout(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
    init2();
  }, []);
  return (
    <>
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20, fontWeight: 500 }}>
        {strings.Faculties}
      </Typography.Title>
      <Typography.Paragraph className="f-15 fw-400">
        <div
          dangerouslySetInnerHTML={{
            __html: about.about,
          }}
          className={"ql-editor"}
        />
      </Typography.Paragraph>
      <div className={classes.VisionSpace31}/>
      <Typography.Title style={{ fontSize: 20, fontWeight: 500 }}>
        {strings.ScholarsforArabicCourses}
      </Typography.Title>
      <div className={classes.VisionSpace31}/>

      {/* Arabic Scholars Profiles */}
      {data.arabic &&
        data.arabic.map((faculity, index) => {
          return <Profile key={index} Faculity={faculity}/>;
        })}
      <div className={classes.VisionSpace}>
        <Typography.Title style={{ fontSize: 20, fontWeight: 500 }}>
          {strings.ScholarsforNonArabicCourses}
        </Typography.Title>
        <Typography.Paragraph className="f-15 fw-500">
          {strings.ScholarsforNonArabicCoursesDescription}
        </Typography.Paragraph>
      </div>

      {/* NON-Arabic Scholars Profiles */}
      <div className={classes.VisionSpace31}>
        {data.nonArabic &&
          data.nonArabic.map((faculity, index) => {
            return <Profile key={index} Faculity={faculity}/>;
          })}
      </div>
    </>
  );
}
function Profile({ Faculity }) {
  const nameStyle = {
    fontWeight: 600,
    fontSize: 15,
    paddingBottom: 11,
    borderBottom: "2px solid #343379",
    marginLeft: "20px",
    marginRight: "20px",
  };
  return (
    <Card
      style={{ marginTop: 16 }}
      bordered={false}
      className="bg-transparent about-us-faculties"
    >
      <Box display="flex">
        {/* <div
          className='about-faculties-image'
          style={{backgroundImage: `url(${Ramadan})`, width: 100, height: 100}}
          width='100'
          height='100'
        /> */}
        <img
          src={Faculity.image}
          alt={Faculity.name}
          width="100"
          height="100"
          style={{
            border: "2px solid #343379",
          }}
        />
        <Box>
          <Typography.Paragraph style={nameStyle}>
            {Faculity.name},             <br/><span style={{fontWeight:400}}>{Faculity.description}</span>

          </Typography.Paragraph>
        </Box>
      </Box>
    </Card>
  );
}
