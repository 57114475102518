import React, { useState, useEffect } from "react";
import strings from "../../../Languages/Language";
import { Card, Row, Typography, Col } from "antd";
import axios from "axios";
import { APP_URL } from "./../../../config";

function KIUDegree() {
  const [kiuDegree, setKiuDegree] = useState({ about: {}, subjects: [] });
  const [error, seterror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${APP_URL}/benefits`)
      .then((res) => {
        setKiuDegree(res.data);
      })
      .catch((err) => {
        seterror("something went wrong");
        setTimeout(() => {
          seterror("");
        }, 2000);
      });
  }, []);
  return (
    <div className="why-kiu-benefits">
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.BenefitofKIUDegree}
      </Typography.Title>
      <div className={"ql-editor mb-30"}
        dangerouslySetInnerHTML={{
          __html: kiuDegree.about?.about,
        }}
      />
      <Row gutter={[60, 10]}>
        {kiuDegree.subjects?.map((val, index) => {
          return (
            <Col key={index} xs={24} md={12}>
              <Card
                bordered={false}
                cover={<img alt="" src={val.image} />}
                style={{ background: "transparent" }}
                bodyStyle={{ padding: "10px 15px", fontWeight: "500" }}
              >
                <Typography.Paragraph>{val.subject}</Typography.Paragraph>
              </Card>
            </Col>
          );
        })}
      </Row>
      {/* <div className="my-2 my-md-4"></div>
      <Typography.Paragraph>
        The diverse modules ensure the students have a well rounded education
        and are able to comprehend all matters relating to the religion.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Pursuing the Islamic Studies degree programme is a structured form of
        seeking knowledge. Seeking knowledge is a worthy pursuit deemed to carry
        great value in the path of worship. Furthermore, applied successfully,
        the Islamic Studies degree will increase the knowledge of Islam in
        students and will instill values that will lead them to become morally
        upstanding citizens who have the ability to contribute positively to
        their families and the society in general.
      </Typography.Paragraph> */}
    </div>
  );
}

export default KIUDegree;
