import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL } from "../../../config";
import { Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Paper } from "@material-ui/core";
import strings from "../../../Languages/Language";
const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 181,
    height: 2,
    backgroundColor: "#343379",
    marginTop: 4,
    marginBottom: 3,
  },
  VisionSpace: {
    marginTop: 51,
  },
  VisionSpace20: {
    marginTop: 20,
  },
  mainBox: {
    boxShadow: "0px 3px 6px #00000038",
    border: "1px solid #EFEFEF",
    background: "white",
    opacity: 1,
  },
}));
function Director() {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/director`)
        .then((response) => {
          setData(response.data);
        })
        .catch(() => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <>
      {error && <div>{error}</div>}


          <Box p={3} component={Paper} className={classes.mainBox}>
            <div
              className="director-image"
              style={{
                backgroundImage: `url('${data?.image}')`,
                width: "100%",
                height: "248px",
              }}
            />
            <div>
              <Typography.Title
                className="text-main f-12 fw-500 my-1"
                style={{ color: "#313060" }}
              >
                {strings.DirectorofKIU}
              </Typography.Title>
              <Typography.Text style={{ fontSize: 19 }}>
                <span className="fw-500"> {data?.first_name}</span>
                <span className="fw-300">{data?.last_name}</span>
              </Typography.Text>
              <p className="director-title-line"></p>
              <Typography.Text className="f-12 fw-500">
                {data?.description}
              </Typography.Text>
              <div className={classes.VisionSpace}/>
              <Typography.Text className="f-15 fw-400">
                <div
                    className={"ql-editor"}
                  dangerouslySetInnerHTML={{
                    __html: data?.message,
                  }}
                />
              </Typography.Text>
            </div>
          </Box>


      <div className={classes.VisionSpace}/>
      <Typography.Text className="f-15 fw-400">
        <div
            className={"ql-editor"}
          dangerouslySetInnerHTML={{
            __html: data?.text,
          }}
        />
      </Typography.Text>
    </>
  );
}

export default Director;
