import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";
import { Card, Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "antd";

const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 199,
    height: 3,
    backgroundColor: "#343379",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 7,
    marginBottom: 3,
  },
  VisionSpace: {
    marginTop: 51,
  },
  VisionSpace20: {
    marginTop: 20,
  },
  mainBox: {
    boxShadow: "0px 3px 6px #00000038",
    border: "1px solid #EFEFEF",
    background: "white",
    opacity: 1,
  },
  card: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000026",
    border: "1px solid #EFEFEF",
  },
}));
export default function CollegesAndInstitutes() {
  const classes = useStyles();
  const [data, setdata] = useState(null);
  const [about, setAbout] = useState({});

  const [error, seterror] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/institutes`)
        .then((res) => {
          setdata(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    const init2 = () => {
      axios
        .get(`${APP_URL}/institutes/about`)
        .then((res) => {
          setAbout(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
    init2();
  }, []);
  return (
    <>
      {error && <div>{error}</div>}
      <Typography.Paragraph className="f-15 fw-400">
        <div
            className={"ql-editor"}
          dangerouslySetInnerHTML={{
            __html: about?.about,
          }}
        ></div>
      </Typography.Paragraph>
      <div className={classes.VisionSpace20}></div>
      <Row gutter={[24, 24]} className="Colleges">
        {data &&
          data.map((college, index) => {
            return (
              <Col xs={24} md={12} key={index}>
                <Card bordered cover={<img alt="" src={college.image} />}>
                  <Typography.Text className="fw-500" style={{ fontSize: 14 }}>
                    {college.name}
                  </Typography.Text>
                  <Typography.Paragraph>
                    {college.description}
                  </Typography.Paragraph>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
}
