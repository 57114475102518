import {Container} from "@material-ui/core";
import React, {useEffect} from "react";
import Box from "@material-ui/core/Box";
import {Breadcrumb, Button, Col, List, Row, Typography} from "antd";
import {Link, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Degree from "../component/OnlineDegree/Degree";
import SyllabusBySemester from "./../component/OnlineDegree/SyllabusBySemester";
import TuitionFees from "./../component/OnlineDegree/TuitionFees";
import AcademicHelp from "./../component/OnlineDegree/AcademicHelp";
import LeftTabsAccordionStyled from "../component/common/leftTabsAccoridionStyled";
import Media from "react-media";
import strings from "../../Languages/Language";

const useStyles = makeStyles(() => ({
    activenav: {
        padding: "10px 20px !important",
        "& span": {
            color: "#079751",
            font: "normal normal normal 14px/21px Poppins",
            padding: "0px 6px 0px 6px",
            borderLeft: "3px solid #33a66e",
        },
        "[dir=rtl] & span": {
            borderLeft: "none !important",
            color: "#8CAA9B",
            borderRight: "3px solid #33a66e",
        },
    },
    inActiveNav: {
        padding: "10px 20px !important",
        "& span": {
            color: "#8CAA9B",
        },
    },
    applyNow: {
        marginTop: 15,
        height: 53,
    },
}));
export default function OnlineDegree() {
    const data = [
        [strings.AboutDegree, "/", 1],
        [strings.SyllabusBySemester, "/", 2],
        [strings.TuitionFees, "/", 3],
        [strings.AcademicHelp, "/", 4],
    ];
    const { name } = useParams();
    const [pageon, setpageon] = React.useState(data[0][2]);
    const classes = useStyles();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <Container maxWidth="xl">
        <Box className="bg-main p-139" marginBottom={3}>
          <div className="m-padding-0">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Online Degree</Breadcrumb.Item>
            </Breadcrumb>
            <Typography.Text className="f-40" style={{ fontWeight: "500" }}>
              {name ?? "Online Degree"}
            </Typography.Text>
          </div>
          <Row gutter={[44, 8]}>
            <Media
              query="(min-width: 768px)"
              render={() => (
                <Col xs={24} md={8} className="sub-nav">
                  <List
                    style={{
                      marginTop: 26,
                      background: "#f3f3f3",
                      paddingRight: "10px",
                      paddingLeft: "1px",
                    }}
                    bordered
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item
                        style={{
                          borderBottom: "0.5px solid rgba(212, 212, 212, 0.7)",
                          minHeight: "50px",
                        }}
                        onClick={() => {
                          setpageon(item[2]);
                        }}
                        className={
                          pageon === item[2]
                            ? classes.activenav
                            : classes.inActiveNav
                        }
                      >
                        <span className="py-0 pl-1">{item[0]}</span>
                      </List.Item>
                    )}
                  />
                    <a href='https://admission.kiu.org/student/login'
                       className={"text-decoration-none"}
                       target={"_blank"}
                    >
                    <Button
                      type="primary"
                      size="large"
                      className={classes.applyNow}
                      block
                    >
                      {strings.ApplyNow}
                    </Button>
                  </a>
                </Col>
              )}
            />
            {/* responsive */}
            <Media
              query="(max-width: 767px)"
              render={() => (
                <Col xs={24} md={8} className="sub-nav p-0 mt-4">
                  <LeftTabsAccordionStyled data={data} setpageon={setpageon} />
                </Col>
              )}
            />
            {/* pages content */}
            <Col xs={24} md={16}>
              <div style={{ marginTop: 26 }}>{getComponent(pageon)}</div>
            </Col>
          </Row>
          <div className="apply-now-responsive mt-2">
              <a href='https://admission.kiu.org/student/login'
                 className={"text-decoration-none"}
                 target={"_blank"}
              >
              <Button
                type="primary"
                size="large"
                className={classes.applyNow}
                block
              >
                {strings.ApplyNow}
              </Button>
            </a>
          </div>
        </Box>
      </Container>
    );
}

function getComponent(page) {
    const data = [
        [strings.Aboutus, "/", 1],
        [strings.SyllabusBySemester, "/", 2],
        [strings.TuitionFees, "/", 3],
        [strings.AcademicHelp, "/", 4],
    ];
    switch (page) {
        case data[0][2]:
        case data[0][0]:
            return <Degree/>;
        case data[1][2]:
        case data[1][0]:
            return <SyllabusBySemester/>;
        case data[2][2]:
        case data[2][0]:
            return <TuitionFees/>;
        case data[3][2]:
        case data[3][0]:
            return <AcademicHelp/>;
        default:
            break;
    }
}
