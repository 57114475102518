import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "antd";
import Box from "@material-ui/core/Box";
import axios from "axios";
import strings from "../../../Languages/Language";
import { APP_URL } from "./../../../config";

const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
    fontWeight: 700,
  },
  line: {
    width: 120,
    height: 1,
    backgroundColor: "#343379",
    marginTop: 10,
    marginLeft: 10,
  },
  VisionSpace: {
    marginTop: 45,
  },
}));
function VisionAndMission() {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/vision`)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          setError("something went wrong");
          setTimeout(() => {
            setError(null);
          }, 2000);
        });
    };
    init();
  }, []);

  const classes = useStyles();

  return (
    <>
      {error && <div>{error}</div>}
      <div>
        <img src={data?.vision?.image}
             className="auto"
             alt="vision"
             style={{
               height: "314px"
            }}
        />
        <div className={classes.VisionSpace}/>
        <Typography.Title className="f-30 fw-500">
          {strings.Vision}
        </Typography.Title>
        <div dangerouslySetInnerHTML={{ __html: data?.vision?.vision }} className={"ql-editor"}>
          {/* <Typography.Text className="f-15 fw-400"></Typography.Text> */}
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center about-us-vision">
        <Row justify="center" className="row-vision-page">
          {data?.visionCounter &&
            data?.visionCounter.map((count, index) => {
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={8}
                  className="d-flex justify-content-center mx-0 my-1 my-sm-5"
                >
                  <Box className="text-center">
                    <p className={classes.numberBox}>{count.count}</p>
                    <Typography.Text className="f-16 fw-500">
                      {count.title}
                    </Typography.Text>
                    <p className={classes.line}/>
                  </Box>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
}

export default VisionAndMission;
