import React, { useState, useEffect } from "react";
import { Col, Row, Typography } from "antd";
import ProgressBar from "../common/ProgressBar";
import strings from "../../../Languages/Language";
import axios from "axios";
import { APP_URL } from "./../../../config";

export default function Assessment() {
  const [data, setdata] = useState({});
  const [error, seterror] = useState(null);

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/assessments`)
        .then((res) => {
          setdata(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <div className="assessment">
      {error && <div>{error}</div>}
      <Typography.Title style={{ fontSize: 20 }}>
        {strings.Assessment}
      </Typography.Title>
      <div
        className={"ql-editor"}
        dangerouslySetInnerHTML={{
          __html: data.about,
        }}
      ></div>
      <Typography.Paragraph className="text-center">
        {/* <small className="text-muted f-14">{data.about}</small> */}
      </Typography.Paragraph>
      <div className="space71"></div>
      <div className="row">
        {/* Session tests */}
        <div className="text-center col-md-6 col-lg-4 col-sm-6 col-xs-6 mt-2">
          <Typography.Paragraph
            style={{ height: "20px", whiteSpace: "nowrap" }}
          >
            {strings.SessionTest}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ height: "20px" }}
            className="text-main f-20 fw-600"
          >
            {data.session_test}%
          </Typography.Paragraph>
          <ProgressBar value={data.session_test} />
        </div>
        {/* Mid Term Exam */}
        <div className="text-center  col-md-6 col-lg-4 col-sm-6 col-xs-6 mt-2">
          <Typography.Paragraph
            style={{ height: "20px", whiteSpace: "nowrap" }}
          >
            {strings.MidTermExam}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ height: "20px" }}
            className="text-main f-20 fw-600"
          >
            {data.mid_term}%
          </Typography.Paragraph>
          <ProgressBar value={data.mid_term} />
        </div>
        {/* Final Exams */}
        <div className="text-center  col-md-6 col-lg-4 col-sm-6 col-xs-6 mt-2">
          <Typography.Paragraph
            style={{ height: "20px", whiteSpace: "nowrap" }}
          >
            {strings.FinalExam}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ height: "20px" }}
            className="text-main f-20 fw-600"
          >
            {data.final_exam}%
          </Typography.Paragraph>
          <ProgressBar value={data.final_exam} />
        </div>
        {/* Assignments */}
        <div className="text-center  col-md-6 col-lg-4 col-sm-6 col-xs-6 mt-2">
          <Typography.Paragraph
            style={{ height: "20px", whiteSpace: "nowrap" }}
          >
            {strings.Assignments}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ height: "20px" }}
            className="text-main f-20 fw-600"
          >
            {data.assignments}%
          </Typography.Paragraph>
          <ProgressBar value={data.assignments} />
        </div>
        {/* Attendacnce */}
        <div className="text-center  col-md-6 col-lg-4 col-sm-6 col-xs-6 mt-2">
          <Typography.Paragraph
            style={{ height: "20px", whiteSpace: "nowrap" }}
          >
            {strings.Attendance}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ height: "20px" }}
            className="text-main f-20 fw-600"
          >
            {data.attendence}%
          </Typography.Paragraph>
          <ProgressBar value={data.attendence} />
        </div>
        {/* Passing Marks */}
        <div className="text-center col-md-6 col-lg-4 col-sm-6 col-xs-6 mt-2">
          <Typography.Paragraph
            style={{ height: "20px", whiteSpace: "nowrap" }}
          >
            {strings.PassMarks}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ height: "20px" }}
            className="text-main f-20 fw-600"
          >
            {data.passing_marks}%
          </Typography.Paragraph>
          <ProgressBar value={data.passing_marks} />
        </div>
      </div>
    </div>
  );
}
