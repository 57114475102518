import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../config";
import strings from "../../../Languages/Language";
import GraduateIcon from "../../../assets/images/grad-students.svg";
import CollegesIcon from "../../../assets/images/colleges.svg";
import FacultyIcon from "../../../assets/images/faculty.svg";
import DegreeIcon from "../../../assets/images/degree.svg";
import ScholarshipIcon from "../../../assets/images/scholarships.svg";

const Statistics = () => {
  const [statsData, setStatsData] = useState({});
  const [error, setError] = useState(null);

  const statsContent = [
    {
      key: "graduated_students",
      title: <>{strings.GraduatedStudents}</>,
      icon: GraduateIcon,
    },
    {
      key: "number_of_colleges",
      title: <>{strings.NumberOfColleges}</>,
      icon: CollegesIcon,
    },
    {
      key: "faculty_in_numbers",
      title: <>{strings.FacultyInNumbers}</>,
      icon: FacultyIcon,
    },
    {
      key: "degree_programs",
      title: <>{strings.GraduatedStudents}</>,
      icon: DegreeIcon,
    },
    {
      key: "number_of_scholarships",
      title: <>{strings.NumberOfScholarships}</>,
      icon: ScholarshipIcon,
    },
  ];

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/statistics`)
        .then((response) => {
          setStatsData(response.data);
        })
        .catch((err) => {
          setError("something went wrong");
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    };
    init();
  }, []);

  return (
    <>
      {error && <div>{error}</div>}
      <div className="w-100 home-bg-light py-md-6 newCard">
        <h2 className={"text-center f-38 fw-600"}>{strings.KIUStatistics}</h2>

        <div
          className={
            "d-flex flex-lg-row flex-md-row flex-md-wrap flex-lg-nowrap flex-column justify-content-center align-items-center gap-100-px space33"
          }
        >
          {statsContent?.map((content) => {
            return (
              <div
                key={content?.key}
                className={
                  "d-flex flex-column justify-content-center align-items-center"
                }
              >
                <img
                  src={content?.icon}
                  alt={content?.title}
                  className={"m-b-25"}
                />
                <h2 className={"f-38 fw-600"}>{statsData[content?.key]}</h2>
                <p
                  className={
                    "f-16 fw-400 text-light-gray mb-0 w-75 text-center"
                  }
                >
                  {content?.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Statistics;
