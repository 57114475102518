import { Link } from "react-router-dom";
import strings from "../../../Languages/Language";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "./../../../config";

const AboutUS = () => {
  const [aboutUsData, setAboutUsData] = useState({
    about: {},
    carosal_data: [],
  });
  const [error, seterror] = useState(null);

  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/home/content`)
        .then((res) => {
          setAboutUsData(res.data);
        })
        .catch((err) => {
          seterror("something went wrong");
          setTimeout(() => {
            seterror("");
          }, 2000);
        });
    };
    init();
  }, []);
  return (
    <>
      {error && <div>{error}</div>}
      <div className="w-100 d-flex justify-content-center home-about-us home-bg-light py-5">
        <div className="w-100 w-md-75 w-responsive-10">
          <h2 className="f-30 fw-500">{strings.AboutKIU}</h2>

          <p
            className="f-15 fw-400 ql-editor"
            dangerouslySetInnerHTML={{ __html: aboutUsData.about?.about }}
          />
          <p className="p-3 learn-more">
            <Link to="/about-us">
              <i className="fa fa-long-arrow-right"/>
              <span className=" f-14 fw-400"> {strings.LearnMore}</span>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUS;
