import React, {useEffect, useState} from "react";
import axios from "axios";
import {APP_URL} from "./../../../config";
import {Breadcrumb, Typography} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {Link, useParams} from "react-router-dom";
import strings from "../../../Languages/Language";

const useStyles = makeStyles(() => ({
    numberBox: {
        width: 142,
        backgroundColor: "#EDEDF5",
        color: "#343379",
        fontSize: 35,
        marginTop: 119,
        textAlign: "center",
        verticalAlign: "center",
        paddingTop: 30,
        paddingBottom: 30,
    },
    line: {
        width: 120,
        height: 3,
        backgroundColor: "#DDDCE9",
        marginTop: 10,
    },
    VisionSpace: {
        marginTop: 45,
    },
}));

function NewsDetails() {
    const classes = useStyles();
    const [news, setnews] = useState({});
    const [error, seterror] = useState(null);
    const {id} = useParams()
    useEffect(() => {
        const init = () => {
            axios
                .get(`${APP_URL}/news/${id}`)
                .then((res) => {
                    setnews(res.data);
                })
                .catch((err) => {
                    seterror("something went wrong");
                    setTimeout(() => {
                        seterror("");
                    }, 2000);
                });
        };
        init();
    }, [id]);
    return (
        <div className="bg-main p-139 m-padding-0">
            {error && <div>{error}</div>}
            <div className="">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">{strings.Home}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/about-us">{strings.Aboutus}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/about-us/6">{strings.NewsAndEvents}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{strings.News}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="space21"/>
            <Typography.Paragraph>
                <Link to="/about-us/6">
                    <ArrowBackIosIcon className="text-submain" style={{fontSize: 20, margin: 'auto 10px'}}/>
                </Link>
                <span
                    className="f-30 fw-500"
                    style={{verticalAlign: "middle", paddingLeft: 10}}
                >
          {news.title}
        </span>
            </Typography.Paragraph>
            <div style={{
                backgroundImage: `url('${news.image}')`,
                height: 600,
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}/>
          <div className={classes.VisionSpace}/>

          <p className="f-20 fw-400 f-xs-20" dangerouslySetInnerHTML={{
                __html: news.description
            }}/>

            <p className="f-20 fw-400 f-xs-20" dangerouslySetInnerHTML={{
                __html: news.text
            }}/>
            <div className={classes.VisionSpace}/>
            <p className="f-20 fw-400 f-xs-20" dangerouslySetInnerHTML={{
                __html: news.date
            }}/>
        </div>
    );
}

export default NewsDetails;
