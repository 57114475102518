import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import Logo from "../../../assets/logo/logo-white.png";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import facebookIcon from "../../../assets/icons/facebook.svg";
import twitterIcon from "../../../assets/icons/twitter.svg";
import instagramIcon from "../../../assets/icons/instagram.svg";
import strings from "./../../../Languages/Language";
import {
  lmsPortalLoginUrl,
  sisPortalLoginUrl,
  sisStudentPortalLoginUrl,
} from "../../../Constants/globals";

const footerColor = { color: "#D3D1E5" };
function FooterComponent() {
  // getting active language
  const locale = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "ar";

  const portalData = [
    {
      name: strings.Portals,
      url: null,
    },
    {
      name: strings.LmsPortal,
      url: lmsPortalLoginUrl[locale],
    },
    {
      name: strings.SisPortal,
      url: sisPortalLoginUrl[locale],
    },
    {
      name: strings.AdmissionLogin,
      url: "https://admission.kiu.org/student/login",
    },
  ];
  const contactData = [
    {
      name: strings.Contact,
      url: null,
    },
    {
      name: strings.ContactUs,
      url: "/about-us/9",
    },
    {
      name: strings.FAQs,
      url: "/about-us/8",
    },
  ];
  const aboutData = [
    {
      name: strings.About,
      url: null,
    },
    {
      name: strings.Aboutus,
      url: "/about-us",
    },
    {
      name: strings.Students,
      url: sisStudentPortalLoginUrl[locale],
    },
    {
      name: strings.Faculty,
      url: "/about-us/4",
    },
  ];
  const connectData = [
    {
      name: strings.Connect,
      url: null,
      icon: null,
    },
    {
      name: "Facebook",
      url: "/",
      icon: facebookIcon,
    },
    {
      name: "Instagram",
      url: "/",
      icon: instagramIcon,
    },
    {
      name: "Twitter",
      url: "/",
      icon: twitterIcon,
    },
  ];
  return (
    <>
      <Row align="flex-end">
        <Col span={12}>
          <img src={Logo} alt="KIU" width={180} />
        </Col>
        <Col span={12} className="d-flex-end-end">
          <span
            className="text-right cursor-pointer mb-0 f-12"
            onClick={() => window.scrollTo(0, 0)}
          >
            <span style={footerColor} className="mr-2">
              {strings.GoBackUp}
            </span>
            <img src={ArrowUp} alt="up" />
          </span>
        </Col>
      </Row>
      <Divider style={{ borderColor: "#FFF", marginTop: 12 }} />
      <Row
        gutter={[72, 30]}
        style={{
          whiteSpace: "nowrap",
          marginLeft: "-12px",
          marginRight: "-12px",
        }}
        className="footer-width pl-4 pl-sm-0"
      >
        {/* Portals */}
        <Col xs={24} md={12} className="pl-0 pl-sm-3 pr-0">
          <FooterListItem data={portalData} />
        </Col>
        {/* Contact us */}
        <Col xs={24} md={12} className="pl-0 pl-sm-3 pr-0">
          <FooterListItem data={contactData} />
        </Col>
        {/* About */}
        <Col xs={24} md={12} className="pl-0 pl-sm-3 pr-0">
          <FooterListItem data={aboutData} />
        </Col>
        {/* Connect */}
        <Col xs={24} md={12} className="pl-0 pl-sm-3 pr-0">
          <FooterListItem data={connectData} />
        </Col>
      </Row>
      <Divider
        style={{ borderColor: "#FFF", marginBottom: 7, marginTop: 40 }}
      />
      <Typography.Paragraph
        className="f-12  d-flex-start-center"
        style={{ fontWeight: "400" }}
      >
        <span style={footerColor} className="f-30">
          ©
        </span>
        &nbsp;&nbsp;
        <span className={"f-12"} style={footerColor}>
          Knowledge International University
        </span>
      </Typography.Paragraph>
    </>
  );
}

export default FooterComponent;

const FooterListItem = ({ data }) => {
  return (
    <div
      style={{ width: "100%", height: "25px" }}
      className="d-flex flex-wrap f-12 justify-content-start footer-list-item-container"
    >
      {/* heading */}
      <div
        style={{
          height: "30px",
        }}
        className="footer-item-heading"
      >
        <ul className="list-inline">
          <li className="list-inline-item w-75">
            <span style={footerColor}>{data[0].name}</span>
          </li>
          <li className="list-inline-item ">
            <Divider type="vertical" />
          </li>
        </ul>
      </div>
      {/* content */}
      <div
        style={{
          width: "50%",
          height: "30px",
        }}
        className="footer-item-content "
      >
        <ul className="list-inline">
          {data
            .filter((item) => {
              return item.url !== null;
            })
            .map((item, index) => {
              return (
                <li
                  className="list-inline-item pr-2 pr-sm-0 px-0 px-sm-2"
                  key={index}
                >
                  {item.icon ? (
                    <img src={item.icon} alt={index} className="mr-2" />
                  ) : (
                    ""
                  )}
                  <a
                    href={item?.url}
                    target={"_blank"}
                    className={"text-decoration-none"}
                  >
                    <span style={footerColor}>{item.name}</span>
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
