// globals enums

import strings from "../Languages/Language";

export const getCollegeFullName = {
  ar: "arabic",
  en: "english",
  fr: "french",
  ur: "urdu",
};

export const sisPortalLoginUrl = {
  ar: "https://ar.kiu.org/sis/admin/login",
  en: "https://en.kiu.org/sis/admin/login",
  fr: "https://fr.kiu.org/sis/admin/login",
  ur: "https://ur.kiu.org/sis/admin/login",
};

export const sisStudentPortalLoginUrl = {
  ar: "https://ar.kiu.org/sis/student/login",
  en: "https://en.kiu.org/sis/student/login",
  fr: "https://fr.kiu.org/sis/student/login",
  ur: "https://ur.kiu.org/sis/student/login",
};

export const lmsPortalLoginUrl = {
  ar: "https://ar.kiu.org/login/index.php",
  en: "https://en.kiu.org/login/index.php",
  fr: "https://fr.kiu.org/login/index.php",
  ur: "https://ur.kiu.org/login/index.php",
};

export const scholarshipUrl = {
  ar: "/admission-and-fee/المنح الدراسية",
  en: "/admission-and-fee/Scholarships",
  fr: "/admission-and-fee/Bourses d'études",
  ur: "/admission-and-fee/وظائف",
};

export const differentPageURLs = [
  "/institution-evidence",
  "/institution-evidence/executive-director",
  "/institution-evidence/board-trustees",
  "/institution-evidence/regulations-policies",
  "/institution-evidence/financial-statement",
  "/institution-evidence/annual-reports",
  "/institution-evidence/strategic-plan",
];
