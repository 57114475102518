import React, { useEffect, useState } from "react";
import { Card, Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Row, Col } from "antd";
import axios from "axios";
import { APP_URL } from "./../../../config";
const useStyles = makeStyles(() => ({
  numberBox: {
    width: 142,
    backgroundColor: "#EDEDF5",
    color: "#343379",
    fontSize: 35,
    marginTop: 119,
    textAlign: "center",
    verticalAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  line: {
    width: 199,
    height: 3,
    backgroundColor: "#343379",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 7,
    marginBottom: 3,
  },
  VisionSpace: {
    marginTop: 51,
  },
  VisionSpace20: {
    marginTop: 20,
  },
  mainBox: {
    boxShadow: "0px 3px 6px #00000038",
    border: "1px solid #EFEFEF",
    background: "white",
    opacity: 1,
  },
  card: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000026",
    border: "1px solid #EFEFEF",
  },
}));
export default function BoardOfTrustees() {
  const [data, setData] = useState({ about: {}, bots: [] });
  const [error, setError] = useState(null);
  useEffect(() => {
    const init = () => {
      axios
        .get(`${APP_URL}/bots`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setTimeout(() => {
            setError(null);
          }, 2000);
        });
    };
    init();
  }, []);
  const classes = useStyles();
  return (
    <>
      {error && <div>{error}</div>}
      <div
        className="f-15 fw-400 ql-editor"
        dangerouslySetInnerHTML={{
          __html: data?.about.about,
        }}
      ></div>
      <div className={classes.VisionSpace20}></div>
      <Row gutter={[24, 24]}>
        {data?.bots &&
          data?.bots.map((trustee, index) => {
            return (
              <Col xs={24} md={12} key={index}>
                <Card className={classes.card}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <img src={trustee.image} alt="" />
                    <Typography.Paragraph className="text-center f-16 fw-500 mb-0 mt-20">
                      <b> {trustee.first_name}</b>
                      <br />
                      {trustee.last_name}
                    </Typography.Paragraph>
                    <div className={classes.line}></div>
                    <Typography.Paragraph className="text-center">
                      <span
                        className="f-12 fw-500"
                        style={{ color: "#313060" }}
                      >
                        {trustee.description}
                      </span>
                    </Typography.Paragraph>
                  </Box>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
}
