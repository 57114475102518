import {Container} from "@material-ui/core";
import React, {useEffect} from "react";
import Box from "@material-ui/core/Box";
import {Breadcrumb} from "antd";
import {Link, useParams} from "react-router-dom";
import {Typography} from "antd";
import {Row, Col} from "antd";
import StudyOverview from "../component/HowItWorks/StudyOverview";
import StudentPortal from "./../component/HowItWorks/StudentPortal";
import StudyMaterial from "./../component/HowItWorks/StudyMaterial";
import VideoLectures from "./../component/HowItWorks/VideoLectures";
import LiveSessions from "./../component/HowItWorks/LiveSessions";
import StudentForum from "../component/HowItWorks/StudentForum";
import Assessment from "./../component/HowItWorks/Assessment";
import VerticalStepper from "../component/common/VerticalStepper";
import LeftTabsAccordionStyled from "../component/common/leftTabsAccoridionStyled";
import Media from "react-media";
import strings from "./../../Languages/Language";

export default function HowItWorks() {
  const data = [
    [strings.StudyOverview, "/", 1],
    [strings.StudentPortal, "/", 2],
    [strings.StudyMaterial, "/", 3],
    [strings.VideoLectures, "/", 4],
    [strings.LiveSessions, "/", 5],
    [strings.KIUStudentForum, "/", 6],
    [strings.Assessment, "/", 7],
  ];

  const {page} = useParams();
  const [pageon, setpageon] = React.useState(page ? page : data[0][2]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth='xl'>
      <Box className='bg-main p-139' marginBottom={3}>
        <div className='m-padding-0'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/'>{strings.Home}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{strings.HowItWorks}</Breadcrumb.Item>
          </Breadcrumb>
          <Typography.Text className='f-40' style={{fontWeight: "500"}}>
            {strings.HowItWorks}
          </Typography.Text>
        </div>
        <Row gutter={[44, 8]}>
          <Media
            query='(min-width: 768px)'
            render={() => (
              <Col xs={24} md={8} className='sub-nav'>
                <VerticalStepper
                  data={data}
                  setPageOn={setpageon}
                  pageOn={pageon}
                />
              </Col>
            )}
          />
          {/* responsive */}
          <Media
            query='(max-width: 767px)'
            render={() => (
              <Col xs={24} md={8} className='sub-nav p-0 mt-4'>
                <LeftTabsAccordionStyled data={data} setpageon={setpageon} />
              </Col>
            )}
          />

          {/* pages content */}
          <Col xs={24} md={16}>
            <div style={{marginTop: 26}}>{getComponent(pageon)}</div>
          </Col>
        </Row>
      </Box>
    </Container>
  );
}
function getComponent(page) {
  const data = [
    [strings.StudyOverview, "/", 1],
    [strings.StudentPortal, "/", 2],
    [strings.StudyMaterial, "/", 3],
    [strings.VideoLectures, "/", 4],
    [strings.LiveSessions, "/", 5],
    [strings.KIUStudentForum, "/", 6],
    [strings.Assessment, "/", 7],
  ];

  switch (page) {
    case data[0][2]:
    case data[0][0]:
      return <StudyOverview />;
    case data[1][2]:
    case data[1][0]:
      return <StudentPortal />;
    case data[2][2]:
    case data[2][0]:
      return <StudyMaterial />;
    case data[3][2]:
    case data[3][0]:
      return <VideoLectures />;
    case data[4][2]:
    case data[4][0]:
      return <LiveSessions />;
    case data[5][2]:
    case data[5][0]:
      return <StudentForum />;
    case data[6][2]:
    case data[6][0]:
      return <Assessment />;
    default:
      break;
  }
}
