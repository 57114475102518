import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import HomeTabContent from "../common/HomeTabContent";
import Media from "react-media";
import strings from "./../../../Languages/Language";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: "rgba(0, 0, 0, .03)",
    marginBottom: 1,
    minHeight: 56,
    textAlign: "center",
    color: "#343379",
    "&$expanded": {
      minHeight: 56,
      backgroundColor: "#089752",
      color: "white",
    },

    "MuiAccordionSummary-content": {
      padding: 100,
    },
    backgroundColor: "#E2E2E2",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function AccordionStyleTabs() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };
  const tabContent = {
    applicationContent: {
      name: strings.AdmissionAndRegistration,
      description: strings.AdmissionAndRegistrationDescription,
      buttons: [
        { key: "1", label: strings.ApplyNow },
        { key: "2", label: strings.Programs },
        { key: "3", label: strings.Scholarships },
      ],
    },
    studentGuide: {
      name: strings.StudentGuide,
      description: strings.StudentGuideDescription,
      buttons: [
        {
          key: "1",
          label: strings.EditingAssignmentsGuide,
        },
        {
          key: "2",
          label: strings.ForumParticipationGuide,
        },
        { key: "3", label: strings.AssignmentsHandInGuide },
      ],
    },
    interactiveLectures: {
      name: strings.InteractiveLectures,
      description: strings.InteractiveLecturesDescription,
      buttons: [
        {
          key: "1",
          label: strings.VirtualClassroomUserGuide,
        },
        {
          key: "2",
          label: strings.RecordedSessionAccess,
        },
        { key: "3", label: strings.VirtualClassroomGuideIOS },
        { key: "4", label: strings.VirtualClassroomGuideAndroid },
        { key: "5", label: strings.SoundCheckGuide },
        { key: "6", label: strings.SoundCheckGuide },
      ],
    },
    portalLogin: {
      name: strings.PortalLogin,
      description: strings.PortalLoginDescription,
      buttons: [
        { key: "1", label: strings.SisPortal },
        { key: "2", label: strings.LMSLogin },
        { key: "3", label: strings.AdmissionLogin },
      ],
    },
    academicCalender: {
      name: strings.AcademicCalendar,
      description: strings.AcademicCalendarDescription,
      buttons: [{ key: "1", label: strings.Download }],
    },
  };

  return (
    <Media
      query="(max-width: 800px)"
      render={() => (
        <div
          className="bg-main p-139"
          style={{
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
          }}
        >
          <div>
            {/* Application Content */}
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              {/* Application Content */}
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>{tabContent.applicationContent["name"]}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HomeTabContent tabContent={tabContent.applicationContent} />
              </AccordionDetails>
            </Accordion>
            {/* Student Guide */}
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              {/* Application Content */}
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>{tabContent.studentGuide["name"]}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HomeTabContent tabContent={tabContent.studentGuide} />
              </AccordionDetails>
            </Accordion>
            {/* Interactive Lectures */}
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              {/* Application Content */}
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>
                  {tabContent.interactiveLectures["name"]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HomeTabContent tabContent={tabContent.interactiveLectures} />
              </AccordionDetails>
            </Accordion>
            {/* Portal Login */}
            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              {/* Application Content */}
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>{tabContent.portalLogin["name"]}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HomeTabContent tabContent={tabContent.portalLogin} />
              </AccordionDetails>
            </Accordion>
            {/* Academic Calender */}
            <Accordion
              square
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              {/* Application Content */}
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>{tabContent.academicCalender["name"]}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HomeTabContent tabContent={tabContent.academicCalender} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
    />
  );
}
